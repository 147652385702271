import React from "react";
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from "react-accessible-accordion";

class Support extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">

                    <section className="">
                        <div className="container">
                            <div className="section-title">
                                <h2 className="sec3-h3">Support & FAQ's</h2>
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>What is FinancialsBook.com refund policy?</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                "Financialsbook.com" is committed to your satisfaction. If you have purchased Software Development/Marketing Services/Hosting Services/Accounting Services from Financialsbook.com and are unhappy with the service receive.You are NOT eligible for any refund but we will try to resolve your issue if reported within 30 days of handover. Please be informed that we DO NOT accept CASH payment method.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>What are the Payment terms?</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                The Payment terms are generally decided and agreed in Initial Document within the scope / milestones of each projects.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>What are the Payment methods?</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                After project signup you'll receive Invoice from our team along with payment link if you wish to pay online (PayPal, Credit / Debit Card). We also accept Telegraphic Transfer to our bank account.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>How I can buy Domain Name OR Hosting Services?:</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                You can directly create account and purchase Domain / Hosting Services at https://hosting.financialsbook.com and if you wish to purchase .pk domain name you can send us query at sales@financialsbook.com
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>Can you visit FinancialsBook.com office at any time?</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                No, you cannot visit our office at anytime. The meetings are only allowed upon appointments.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>How I can get appointment?:</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p class="FAQ ans">
                                                You can contact us via email (sales@financialsbook.com) and sometime will schedule your appointment after initial discussion / requirement.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}
export default Support;
