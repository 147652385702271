import React from "react";
import 'react-bootstrap';
import "./css/Navbar.css";
import "./css/Footer.css";
import "./css/Pages.css";
import "./css/SubPages.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import WebApplicationDevelopment from "./pages/Services/WebApplicationDevelopment";
import AccountingTaxConsulting from "./pages/Services/AccountingTaxConsulting";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/SubPages/Pricing";
import ItConsulting from "./pages/Services/ItConsulting";
import FinancialConsultingService from "./pages/Services/FinancialConsultingService";
import Support from "./pages/Services/Support";
import Partners from "./pages/Partners";
import AuditingConsulting from "./pages/Services/AuditingConsulting";
import WebDesigning from "./pages/Services/WebDesigning";
import GraphicDesigning from "./pages/Services/GraphicDesigning";
import EnterpriseSoftwareDevelopment from "./pages/Services/EnterpriseSoftwareDevelopment";
import DigitalMarketing from "./pages/Services/DigitalMarketing";
import DomainHostingService from "./pages/Services/DomainHostingService";
import Blog from "./pages/Blog";
import KnowYourTaxRate from "./pages/SubPages/KnowYourTaxRate";
import IncomeTaxReturnDeadline from "./pages/SubPages/IncomeTaxReturnDeadline";
import BusinessRegistrationServices from "./pages/Services/BusinessRegistrationServices";
import SalesDistributionBusiness from "./pages/Services/SalesDistributionBusiness";



function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/AboutUs" element={<AboutUs />}/>
                <Route path="/Services/WebApplicationDevelopment" element={<WebApplicationDevelopment />}/>
                <Route path="/Services/AccountingTaxConsulting" element={<AccountingTaxConsulting />}/>
                <Route path="/Services/ItConsulting" element={<ItConsulting />}/>
                <Route path="/Services/FinancialConsultingService" element={<FinancialConsultingService />} />
                <Route path="/Services/AuditingConsulting" element={<AuditingConsulting />} />
                <Route path="/Services/WebDesigning" element={<WebDesigning />} />
                <Route path="/Services/GraphicDesigning" element={<GraphicDesigning />} />
                <Route path="/Services/EnterpriseSoftwareDevelopment" element={<EnterpriseSoftwareDevelopment />} />
                <Route path="/Services/DigitalMarketing" element={<DigitalMarketing />} />
                <Route path="/Services/DomainHostingService" element={<DomainHostingService />} />
                <Route path="/Services/BusinessRegistrationServices" element={<BusinessRegistrationServices />} />
                <Route path="/Services/SalesDistributionBusiness" element={<SalesDistributionBusiness />} />
                <Route path="/Services/Support" element={<Support />}/>

                <Route path="/Blog" element={<Blog />}/>
                <Route path="/Blog/KnowYourTaxRate" element={<KnowYourTaxRate />} />
                <Route path="/blog/IncomeTaxReturnDeadline" element={<IncomeTaxReturnDeadline />} />


                <Route path="/Pricing" element={<Pricing />}/>
                <Route path="/Partners" element={<Partners />}/>
                <Route path="/ContactUS" element={<ContactUs/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
