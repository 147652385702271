import React from "react";
import { BsFacebook } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";

class Footer extends React.Component {
    render() {
        return(
            <>
                {/* Footer */}
                    <footer className="footer-20192">
                    <div className="site-section">
                        <div className="container">
                            <div className="row" style={{marginBottom: "2rem"}}>
                                <div className="col-sm">
                                    <a href="#" className="footer-logo">
                                        <img src={"../img/fblogo.jpg"} width="250" height="auto" />
                                    </a>
                                </div>
                                <div className="col-sm">
                                    <h3>Company</h3>
                                    <ul className="list-unstyled links">
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="/AboutUs">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Blog</a>
                                        </li>
                                        <li>
                                            <a href="/ContactUS">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm">
                                    <h3>Quick Links</h3>
                                    <ul className="list-unstyled links">
                                        <li>
                                            <a href="https://onesuite.work/" target="_blank">OneSuite.work</a>
                                        </li>
                                        <li>
                                            <a href="/ContactUS">Desktop Softwares</a>
                                        </li>
                                        <li>
                                            <a href="/Pricing">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="/Services/Support">Support</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm">
                                    <h3>Get In Touch</h3>
                                    <ul className="list-unstyled links">
                                        <li className="location-li">
                                            <div>
                                                <IoLocation
                                                    style={{
                                                        color: 'rgb(255 255 255)',
                                                        fontSize: '25px',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            </div>
                                            <a href="https://www.google.com/maps/place/FinancialsBook.com/@31.4200149,73.1094483,17z/data=!3m1!4b1!4m6!3m5!1s0x39226960c10e5ba7:0x133727eb11922127!8m2!3d31.4200149!4d73.1094483!16s%2Fg%2F11gmfbv4mc?entry=ttu" target="_blank">
                                                Head Office Faisalabad : Building 9, Muhammadia Colony, Canal Road</a>
                                        </li>
                                        <li className="location-li">
                                            <div>
                                                <AiOutlineMail
                                                    style={{
                                                        color: 'rgb(255 255 255)',
                                                        fontSize: '25px',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            </div>
                                            <a href="mailto:sales@financialsbook.com" target="_blank">sales@financialsbook.com</a>

                                        </li>
                                        <li className="location-li">
                                            <div>
                                                <BiSolidPhoneCall
                                                    style={{
                                                        color: 'rgb(255 255 255)',
                                                        fontSize: '25px',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            </div>
                                            <a href="#">(+92) 331-606-5511</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <h3 style={{textAlign: 'center'}}>Follow us</h3>
                                    <ul className="list-unstyled social">
                                        <li>
                                            <a href="https://www.facebook.com/financialsbook/" target="_blank">
                                                <div
                                                    style={{
                                                        margin: '4px'
                                                    }}
                                                >
                                                    <BsFacebook
                                                        style={{
                                                            color: "#000000",
                                                            fontSize: '25px'
                                                        }}
                                                    />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/financialsbook/" target="_blank" >
                                                <div
                                                    style={{
                                                        margin: '4px'
                                                    }}
                                                >
                                                    <BsInstagram
                                                        style={{
                                                            color: "#000000",
                                                            fontSize: '25px'
                                                        }}
                                                    />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/financialsbook/" target="_blank">
                                                <div
                                                    style={{
                                                        margin: '4px'
                                                    }}
                                                >
                                                    <BsLinkedin
                                                        style={{
                                                            color: "#000000",
                                                            fontSize: '25px'
                                                        }}
                                                    />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Copyright */}
                            <div className="text-center p-3"
                                 style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                Copyright © 2024
                                <span style={{marginLeft: "10px"}} >
                                    <a className="text-white" href="https://financialsbook.com/">
                                        FinancialsBook.com  | All Rights Reserved.
                                    </a>
                                </span>
                            </div>
                            {/* Copyright */}
                        </div>
                    </div>
                </footer>
                {/* Footer */}


            </>

        );
    }
}
export default Footer;
