import React from "react";

class EnterpriseSoftwareDevelopment extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Leverage our ten-year expertise to create a solid software foundation for your business</h3>
                            </div>
                            <button className="contact-change6">
                                <a href="/ContactUS"> Lets Develop Your Website</a>
                            </button>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/ESD1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Custom Enterprise Software development</h3>
                                    <p>Support your business infrastructure with scalable software that improves key facets of your enterprise,
                                        from automation to employee collaboration. Leverage our decade-long expertise to build an enterprise
                                        software solution that solves a particular business objective in one of your departments.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/ESD2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        SOFTWARE INTEGRATION
                                    </h3>
                                    <p>Enterprises like yours rely on well-coordinated SaaS and on-premise third-party applications. Improve your software infrastructure with well-built microservices, reliable API, and data integration. Launch your mobile strategy with
                                        our comprehensive enterprise application development, or use our enterprise app integration solutions.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/ESD3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Legacy Application Modernization
                                    </h3>
                                    <p>Outdated enterprise applications often cause employees to spend more time working around limitations. Hire our team to perform an in-depth feature and technical analysis of your legacy solution and improve it with the latest tools and technologies.
                                        A well-built legacy solution will help your employees become more productive and satisfied</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/ESD4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Application Portfolio Consolidation
                                    </h3>
                                    <p style={{textAlign: 'left'}}>Frequent mergers and acquisitions leave enterprises with a wide variety of uncoordinated software
                                        solutions. Our experts will help you consolidate, integrate, and refactor your enterprise software
                                        development&nbsp; as necessary, moving them to modern software stacks and cloud hosting. You will
                                        streamline IT operations, minimize costs, and increase employee efficiency.
                                        <span style={{fontSize: '14px'}}>
                                            &nbsp;
                                        </span>
                                        When companies grow, either organically or by acquisition, their IT grows with it. This tends not
                                        to be a big issue in growth mode, but it inevitably reaches a tipping point. It costs too much to
                                        support all the applications, and risk increases as resources are spread across too many projects.
                                        When deciding on which applications to keep, merge or retire, the process becomes a turf battle ¨C
                                        influence and opinion becomes the driving factors in these decisions.
                                        By the time application quality/risk is taken into account, it ¯is often too late to change course.
                                    </p>
                                    <p style={{textAlign: 'left'}}>
                                        <b>The document outlines the top 5 critical insights to keep in mind when starting a new rationalization or
                                        consolidation initiatives.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default EnterpriseSoftwareDevelopment;
