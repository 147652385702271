import React from "react";
import {AiOutlineSearch} from "react-icons/ai";

class KnowYourTaxRate extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <div className="sect2-textpart">
                        <div className="section-title">
                            <h2 className="price-head sec2-h2">Know Your Tax Rate</h2>
                        </div>

                            {/* ======= Blog Section ======= */}
                            <section id="blog" className="blog">
                                <div className="container table-blog" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-lg-8 entries">
                                            <div className="col-md-10">
                                                <ul className="nav nav-fill nav-tabs" role="tablist">
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags active" id="fill-tab-0" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-0" role="tab"
                                                           aria-controls="fill-tabpanel-0" aria-selected="true">SALARIED INDIVIDUALS</a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-1" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-1" role="tab"
                                                           aria-controls="fill-tabpanel-1" aria-selected="false"> NON SALARIED & AOPs </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-2" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-2" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false"> NON SALARIED & AOPs </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-3" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-3" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false">INCOME FROM PROPERTY </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-4" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-4" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false"> IMMOVABLE PROPERTY </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-5" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-5" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false"> MINIMUM TAX </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-6" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-6" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false">DISPOSAL OF SECURITIES </a>
                                                    </li>
                                                    <li className="nav-item blog-tabslist" role="presentation">
                                                        <a className="nav-link blog-atags" id="fill-tab-7" data-bs-toggle="tab"
                                                           href="#fill-tabpanel-7" role="tab"
                                                           aria-controls="fill-tabpanel-2" aria-selected="false"> DISCHARGE OF TAX </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="tab-content pt-5" id="tab-content">
                                                <div className="tab-pane active" id="fill-tabpanel-0" role="tabpanel" aria-labelledby="fill-tab-0">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>TAX RATES FOR NON SALARIED &
                                                            AOPs</h5>
                                                        <p>[Division I, Part of the First Schedule]</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                    {/*Table head*/}
                                                    <thead>
                                                    <tr>
                                                        <th style={{color: 'white'}}>S.No.</th>
                                                        <th style={{color: 'white'}}>Taxable Income</th>
                                                        <th style={{color: 'white'}}>Rate of tax</th>
                                                    </tr>
                                                    </thead>
                                                    {/*Table head*/}
                                                    {/*Table body*/}
                                                    <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Upto Rs.600,000</td>
                                                        <td>0%</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>Rs. 600,001 to Rs. 1,200,000</td>
                                                        <td>5% on amount exceeding Rs. 600,000</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td>Rs. 1,200,001 to Rs. 1,800,000 R</td>
                                                        <td>Rs. 30,000 plus 10 % on amount > Rs. 1.2M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">4</th>
                                                        <td>Rs. 1,800,001 to Rs. 2,500,000</td>
                                                        <td>Rs. 90,000 plus 15 % on amount > Rs. 1.8M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">5</th>
                                                        <td>Rs. 2,500,001 to Rs.3,500,000</td>
                                                        <td>Rs. 195,000 plus 17.5 % on amount > Rs. 2.5M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">6</th>
                                                        <td>Rs. 3,500,001 to Rs.5,000,000</td>
                                                        <td>Rs. 370,000 plus 20 % on amount > Rs. 3.5M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">7</th>
                                                        <td>Rs. 5,000,001 to Rs.8,000,000</td>
                                                        <td>Rs. 670,000 plus 22.5 % on amount > Rs. 5M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">8</th>
                                                        <td>Rs. 8,000,001 to Rs.12,000,000 R</td>
                                                        <td>Rs. 1,345,000 plus 25 % on amount > Rs. 8M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">9</th>
                                                        <td>Rs. 12,000,001 to Rs.30,000,000</td>
                                                        <td>Rs. 2,345,000 plus 27.5 % on amount > Rs. 12M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">10</th>
                                                        <td>Rs. 30,000,001 to Rs.50,000,000</td>
                                                        <td>Rs. 7,295,000 plus 30 % on amount > Rs. 30M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">11</th>
                                                        <td>Rs. 50,000,001 to Rs.75,000,000 R</td>
                                                        <td>Rs. 13,295,000 plus 32.5 % on amount > Rs. 50M</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12</th>
                                                        <td>Above 75,000,000</td>
                                                        <td>Rs. 21,420,000 plus 35 % on amount > Rs. 75M</td>
                                                    </tr>
                                                    </tbody>
                                                    {/*Table body*/}
                                                </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-1" role="tabpanel" aria-labelledby="fill-tab-1">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>TAX RATES FOR NON SALARIED &
                                                            AOPs</h5>
                                                        <p>[Division I, Part of the First Schedule]</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>S.No.</th>
                                                            <th style={{color: 'white'}}>Taxable Income</th>
                                                            <th style={{color: 'white'}}>Rate of tax</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Upto Rs. 400,000</td>
                                                            <td>0%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Rs. 400,001 to Rs. 600,000</td>
                                                            <td>5% of Amount Exceeding Rs. 400,000/-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Rs. 600,001 to Rs. 1,200,000</td>
                                                            <td>Rs. 10,000/- plus 10% Exceeding Rs. 600,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Rs. 1,200,001 to Rs. 2,400,000</td>
                                                            <td>Rs. 70,000/- plus 15% Exceeding Rs. 1.2M</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Rs. 2,400,001 to Rs.3,000,000</td>
                                                            <td>Rs. 250,000/- plus 20% Exceeding Rs. 2.4M</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>Rs. 3,000,001 to Rs. 4,000,000 R</td>
                                                            <td>Rs. 370,000/- plus 25% Exceeding Rs. 3M </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>Rs. 4,000,001 to Rs. 6,000,000</td>
                                                            <td>Rs. 620,000/- plus 30% Exceeding Rs. 3M </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>Above Rs. 6,000,000</td>
                                                            <td>Rs. 1,220,000/- plus 35% Exceeding Rs. 6M</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-2" role="tabpanel" aria-labelledby="fill-tab-2">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>TAX RATES FOR NON SALARIED &
                                                            AOPs</h5>
                                                        <p>[Division I, Part of the First Schedule]</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>S.No.</th>
                                                            <th style={{color: 'white'}}>Type</th>
                                                            <th style={{color: 'white'}}>Rate of tax</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Banking Companies</td>
                                                            <td>35%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Public /Private Companies</td>
                                                            <td>29%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Small Companies</td>
                                                            <td>22%</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-3" role="tabpanel" aria-labelledby="fill-tab-3">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>TAX RATES FOR INCOME FROM PROPERTY</h5>
                                                        <p>FOR INDIVIDUALS & AOPs <br /> Sections 15 % 155</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>S.No.</th>
                                                            <th style={{color: 'white'}}>Gross amount of rent</th>
                                                            <th style={{color: 'white'}}>Rate of tax</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Upto Rs. 200,000</td>
                                                            <td>0%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Rs. 200,001 to Rs. 600,000</td>
                                                            <td>5% of Amount Exceeding Rs. 200,000/-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Rs. 600,001 to Rs. 1,000,000</td>
                                                            <td>Rs. 20,000/- + 10% Exceeding Rs. 600,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Rs. 1,000,001 to Rs. 2,000,000</td>
                                                            <td>Rs. 60,000/- + 15% Exceeding Rs. 1,000,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Rs. 2,000,001 to Rs. 4,000,000</td>
                                                            <td>Rs. 210,000/- + 20% Exceeding Rs. 2,000,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>Rs. 4,000,001 to Rs. 6,000,000</td>
                                                            <td>Rs. 610,000/- + 25% Exceeding Rs. 4,000,000 </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>Rs. 6,000,001 to Rs. 8,000,000</td>
                                                            <td>Rs. 1,110,000/- + 30% Exceeding Rs. 6,000,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>Above Rs. 8,000,000</td>
                                                            <td>Rs. 1,710,000/- + 35% Exceeding Rs. 8,000,000</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">*</th>
                                                            <td colspan="2">IND/AOP may opt for Normal Tax Regime without any rental income limit</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td colspan="2">** In case of Company, rate of tax u/s 155 shall be 15% of the gross amount of the rent</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-4" role="tabpanel" aria-labelledby="fill-tab-4">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>CAPITAL GAIN ON DISPOSAL OF IMMOVABLE PROPERTY</h5>
                                                        <p>Section 37(1A)</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>S.No.</th>
                                                            <th style={{color: 'white'}}>Amount of Gain</th>
                                                            <th style={{color: 'white'}}>On ATL</th>
                                                            <th style={{color: 'white'}}>Not On ATL</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Where the gain does not exceed Rs. 5 M</td>
                                                            <td>2.5%</td>
                                                            <td>5%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Where the gain does not exceed Rs. 5 M but does not exceed Rs.10M</td>
                                                            <td>5%</td>
                                                            <td>10%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Where the gain does not exceed Rs. 5 M but does not exceed Rs.10M</td>
                                                            <td>7.5%</td>
                                                            <td>15%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Where the gain does not exceed Rs. 5 M but does not exceed Rs.10M</td>
                                                            <td>10%</td>
                                                            <td>20%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td colSpan="3">* If Holding Period is more than 4 years then Capital Gain is Subject to Tax @ 0%.</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-5" role="tabpanel" aria-labelledby="fill-tab-5">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>MINIMUM TAX</h5>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>Nature of Payments</th>
                                                            <th style={{color: 'white'}}>Section</th>
                                                            <th style={{color: 'white'}}>On ATL</th>
                                                            <th style={{color: 'white'}}>Not On ATL</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">MINIMUM TURNOVER Tax for Companies including P.E of
                                                                non-resident Company(except otherwise specified)</th>
                                                            <td>113</td>
                                                            <td colSpan="2">1.50% of Turnover</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">MINIMUM TURNOVER Tax for Ind. & AOPs (annual turnover Rs. 10 Million or more)</th>
                                                            <td>113</td>
                                                            <td colSpan="2">1.50% of Turnover</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">In case of Oil Marketing Companies,
                                                                Oil Refineries, SSGPL,SNGPL,PIAC, Poultry & online marketplace</th>
                                                            <td>113</td>
                                                            <td>0.75% of Turnover</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Motorcycle dealers</th>
                                                            <td>113</td>
                                                            <td>0.30% of Turnover</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">In case of distributers, Flour Milling,
                                                                Petroleum, Agents,Rice Mills & Dealers</th>
                                                            <td>113</td>
                                                            <td colSpan="2">0.25% of Turnover</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" style={{textDecoration: 'underline'}}>Imports (For Details please refer Twelfth Schedule)</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Twelfth Schedule Table I</th>
                                                            <td>148</td>
                                                            <td>1.00%</td>
                                                            <td>2.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Twelfth Schedule Table II</th>
                                                            <td>148</td>
                                                            <td>2.00%</td>
                                                            <td>4.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Twelfth Schedule Table III</th>
                                                            <td>148</td>
                                                            <td>5.50%</td>
                                                            <td>11.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">SRO 1125(I/2015 Manufacturer Importing items covered under said SRO</th>
                                                            <td>148</td>
                                                            <td>1.00%</td>
                                                            <td>2.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Persons importing finished pharmaceutical products</th>
                                                            <td>148</td>
                                                            <td>4.00%</td>
                                                            <td>8.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Dividend</th>
                                                            <td>150</td>
                                                            <td>7.50% to 25%</td>
                                                            <td>15% to 50%</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">Profit on Debt:( Other than Companies)</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">-upto 500,000</th>
                                                            <td>151</td>
                                                            <td>10%</td>
                                                            <td>20%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">-above 500,000</th>
                                                            <td>151</td>
                                                            <td>15%</td>
                                                            <td>30%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Royalty or Fee for Technical Services received by N.R</th>
                                                            <td>152(1)</td>
                                                            <td colSpan="2" className="text-center">15.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Contracts by N.R</th>
                                                            <td>152(A1)</td>
                                                            <td>7.00%</td>
                                                            <td>14.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">Sale of Goods made by:</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Companies</th>
                                                            <td>153(1)(a)</td>
                                                            <td>4.00%</td>
                                                            <td>8.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Others</th>
                                                            <td>153(1)(a)</td>
                                                            <td>4.50%</td>
                                                            <td>9.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sale of Rice, Cotton Seeds, Edible Oils to Prescribe Persons</th>
                                                            <td>153(1)(a)</td>
                                                            <td>1.5%</td>
                                                            <td>3.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="3">
                                                                Sale of Fast Moving consumer goods by Distributers:
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Companies</th>
                                                            <td>153(1)(a)</td>
                                                            <td>2.00%</td>
                                                            <td>4.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Others</th>
                                                            <td>153(1)(a)</td>
                                                            <td>2.50%</td>
                                                            <td>5.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Supplies to Five Sector – Clause 45A(a) , Part IV of 2nd
                                                                Schedule</th>
                                                            <td>153(1)(a)</td>
                                                            <td>0.5%</td>
                                                            <td>1%</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="3" style={{textDecoration: 'underline'}}>
                                                                Electronics & Print media Advertising Services:
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Companies</th>
                                                            <td>153(1)(b)</td>
                                                            <td>1.50%</td>
                                                            <td>3.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Others</th>
                                                            <td>153(1)(b)</td>
                                                            <td>1.50%</td>
                                                            <td>3.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="3" style={{textDecoration: 'underline'}}>
                                                                Contracts:-
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">– Companies other than Listed Companies</th>
                                                            <td>153(1)(c)</td>
                                                            <td>7.00%</td>
                                                            <td>14.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">– Other Taxpayers Except Sportsperson</th>
                                                            <td>153(1)(c)</td>
                                                            <td>7.50%</td>
                                                            <td>15.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center">- Sportspersons</th>
                                                            <td>153(1)(c)</td>
                                                            <td>10%</td>
                                                            <td>20.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Services (Companies) other than Specified</th>
                                                            <td>153(1)b</td>
                                                            <td>8.00%</td>
                                                            <td>16.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Services (By Others) other than Specified</th>
                                                            <td>153(1)b</td>
                                                            <td>10.00%</td>
                                                            <td>17.50%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Specified Services</th>
                                                            <td>153(1)b</td>
                                                            <td>3.00%</td>
                                                            <td>6%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Services to Five Sector – Clause 45A(a) , Part IV of 2nd
                                                                Schedule</th>
                                                            <td>153(1)b</td>
                                                            <td>0.5%</td>
                                                            <td>1%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Services of Stitching, Dyeing, Printing, Embroidery,
                                                                Washing, Sizing & Weaving Rendered to Exporter or
                                                                Export House</th>
                                                            <td>153(2)</td>
                                                            <td colSpan="2">1.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Advertising Agents Commission</th>
                                                            <td>233(1)</td>
                                                            <td>10%</td>
                                                            <td>20%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Life Insurance Agents Commission (If less than 0.5 M)</th>
                                                            <td>233(1)</td>
                                                            <td>8%</td>
                                                            <td>16%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Brokerage & Commission (Other than above)</th>
                                                            <td>233(1)</td>
                                                            <td>12%</td>
                                                            <td>24%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>CNG stations (On amount of Gas Bill)</th>
                                                            <td>234A</td>
                                                            <td>4%</td>
                                                            <td>8%</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Electricity Bills (Ind. & AOPs)</th>
                                                            <td>235(4)(a)</td>
                                                            <td colSpan="2">upto Bill Rs. 360,000 P.A</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-6" role="tabpanel" aria-labelledby="fill-tab-6">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>CAPITAL GAIN ON DISPOSAL OF SECURITIES</h5>
                                                        <p>Section 37A</p>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>S.No.</th>
                                                            <th style={{color: 'white'}}>Holding Period</th>
                                                            <th style={{color: 'white'}}>On ATL</th>
                                                            <th style={{color: 'white'}}>Not On ATL</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td>Acquired before July 1, 2016: </td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Sold in less than 12 months</td>
                                                            <td>15.00%</td>
                                                            <td>30.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Sold after 12 but before 24 months</td>
                                                            <td>12.50%</td>
                                                            <td>25.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Sold after 24 months and acquired after 01-07-13 </td>
                                                            <td>7.50%</td>
                                                            <td>15%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>Acquired before 01-07-2013 </td>
                                                            <td>0.00%</td>
                                                            <td>0.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td>Acquired after July 1, 2016: </td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>Irrespective of holding period</td>
                                                            <td>15.00%</td>
                                                            <td>30.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td>Future commodity contractsPMEX</td>
                                                            <td>5.00%</td>
                                                            <td>10.00%</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                                <div className="tab-pane" id="fill-tabpanel-7" role="tabpanel" aria-labelledby="fill-tab-7">
                                                    <div className="table-heading">
                                                        <h5 style={{color: 'black'}}>FINAL DISCHARGE OF TAX</h5>
                                                    </div>
                                                    <table className="table table-striped">
                                                        {/*Table head*/}
                                                        <thead>
                                                        <tr>
                                                            <th style={{color: 'white'}}>Nature of Payments</th>
                                                            <th style={{color: 'white'}}>Section</th>
                                                            <th style={{color: 'white'}}>Filer</th>
                                                            <th style={{color: 'white'}}>Non-Filer</th>
                                                        </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row"></th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Exports</th>
                                                            <td>154</td>
                                                            <td colSpan="2">1.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Indenting Commission</th>
                                                            <td>154(2)</td>
                                                            <td colSpan="2">5.00%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Prize on Prize Bond/ Crossword Puzzle</th>
                                                            <td>156</td>
                                                            <td>15%</td>
                                                            <td>30%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Winning from Raffle, Lottery, Quiz etc</th>
                                                            <td>156</td>
                                                            <td>20%</td>
                                                            <td>40%</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Commission on Petroleum Products</th>
                                                            <td>156(A)</td>
                                                            <td>12.00%</td>
                                                            <td>24.00%</td>
                                                        </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        {/* End blog entries list */}
                                        <div className="col-lg-4">
                                            <div className="sidebar">
                                                <h3 className="sidebar-title">Search</h3>
                                                <div className="sidebar-item search-form">
                                                    <form action="">
                                                        <input type="text" placeholder="search..." />
                                                        <button type="submit">
                                                            <div>
                                                                <AiOutlineSearch
                                                                    style={{
                                                                        color: '#ffffff',
                                                                        fontSize: "25px"
                                                                    }}
                                                                />
                                                            </div>
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* End sidebar search form*/}
                                                <h3 Style={{textAlign: 'left'}} className="sidebar-title">Recent Posts</h3>
                                                <div className="sidebar-item categories">
                                                    <ul Style={{textAlign: 'left'}}>
                                                        <li>
                                                            <a href="https://financialsbook.com/know-your-tax-rate/" aria-current="page">Know Your Tax Rate</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://financialsbook.com/income-tax-return-deadline-30th-september-2020/">Income Tax Return Deadline – 30th September, 2020</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* End sidebar Recent Comment form*/}
                                                <h3 Style={{textAlign: 'left'}} className="sidebar-title">Recent Comments</h3>
                                                <div className="sidebar-item categories">
                                                    <ul Style={{textAlign: 'left'}}>
                                                        <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://bactrimrbv.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin for dogs side effects
                                                                </a>
                                                                on
                                                            </span>
                                                            <a href="https://financialsbook.com/#comment-28">Home</a>
                                                        </li>
                                                        <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://bactrimqwx.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin 500
                                                                </a>
                                                                on
                                                            </span>

                                                            <a href="https://financialsbook.com/#comment-27">Home</a></li>
                                                        <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://cephalexinfds.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin used for
                                                                </a>
                                                                o
                                                            </span>

                                                            <a href="https://financialsbook.com/#comment-26">Home</a></li>
                                                        <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://ciprofloxacinbtg.com" className="url" rel="ugc external nofollow">
                                                                    ciprofloxacin and dexamethasone
                                                                </a>
                                                                on
                                                            </span>

                                                            <a href="https://financialsbook.com/#comment-25">Home</a></li>
                                                        <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://zithromaxeng.com" className="url" rel="ugc external nofollow">
                                                                    buy zithromax
                                                                </a>
                                                                on
                                                            </span>

                                                            <a href="https://financialsbook.com/#comment-24">Home</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* End sidebar Archives form*/}
                                                <h3 Style={{textAlign: 'left'}} className="sidebar-title">Archives</h3>
                                                <div className="sidebar-item categories">
                                                    <ul Style={{textAlign: 'left'}}>
                                                        <li><a href="https://financialsbook.com/2020/09/">September 2020</a></li>
                                                        <li><a href="https://financialsbook.com/2020/08/">August 2020</a></li>
                                                    </ul>
                                                </div>
                                                {/* End sidebar Categories form*/}
                                                <h3 Style={{textAlign: 'left'}} className="sidebar-title">Categories</h3>
                                                <div className="sidebar-item categories">
                                                    <ul Style={{textAlign: 'left'}}>
                                                        <li className="cat-item cat-item-11"><a
                                                            href="https://financialsbook.com/category/tax/">tax</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* End sidebar Meta form*/}
                                                <h3 Style={{textAlign: 'left'}} className="sidebar-title">Meta</h3>
                                                <div className="sidebar-item categories">
                                                    <ul Style={{textAlign: 'left'}}>
                                                        <li><a rel="nofollow" href="https://financialsbook.com/wp-login.php">Log in</a></li>
                                                        <li><a href="https://financialsbook.com/feed/">Entries feed</a></li>
                                                        <li><a href="https://financialsbook.com/comments/feed/">Comments feed</a></li>

                                                        <li><a href="https://wordpress.org/">WordPress.org</a></li>
                                                    </ul>
                                                </div>
                                                {/* End sidebar tags*/}
                                            </div>
                                            {/* End sidebar */}
                                        </div>
                                        {/* End blog sidebar */}
                                    </div>
                                </div>
                            </section>
                            {/* End Blog Section */}


                        {/* End #main */}






                    </div>
                </div>
            </>
        )
    }
}
export default KnowYourTaxRate;
