import React from "react";

class AccountingTaxConsulting extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Website is the Most Powerful Marketing Tool</h3>
                                <button className="contact-change6">
                                    <a href="/Pricing"> View Pricing</a>
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/ac1.png" className="img-fluid" alt="" style={{width: '50%'}} />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Accounting & Tax Consulting
                                    </h3>
                                    <p>
                                        <a href="https://www.instagram.com/financialsbook/" target="_blank" style={{fontSize: '14px', backgroundColor: 'rgb(246, 246, 246)'}}>Financialsbook.com</a>
                                        &nbsp;&nbsp; offers a full spectrum of accounting and tax services, and consulting and advisory services, including traditional auditing and financial reporting; federal, multi-state and international tax compliance and planning; compliance oversight; risk assessment and litigation support services; management, board and technology consulting; family office advisors and wealth management solutions. We proudly provide these accounting and tax services, and consulting and advisory services to privately-owned companies of nearly every type and industry, as well as to financial institutions, nonprofit organizations, government entities, and wealthy individuals and families. By understanding each of our clients and providing hands-on support; achieving innovative solutions through collaboration; providing continuity; anticipating changes with industry insight and expertise; and supporting our community, Financialsbook.com accelerates its clients’ success. Let us do so for you.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/ac2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Outsourced CFO & Accounting Services</h3>
                                    <p>
                                        With  &nbsp;
                                        <a href="https://www.instagram.com/financialsbook/" target="_blank" style={{fontSize: '14px', backgroundColor: 'rgb(246, 246, 246)'}}>Financialsbook.com</a>
                                        &nbsp; you can focus on running and growing your business while your long-term financial goals (and tedious reporting) are managed by the experts. Our custom packages are tailored to your needs, and you’ll benefit from monthly oversight by a seasoned financial professional. Furthermore, We provide a Business Owner/Board Reporting service, CFO Analysis and presentation of performance, monthly management financial reports, company secretarial services as well as networks and connections. We work with your key stakeholders to help implement the financial strategy for your organization and can provide mentoring and assistance to the existing finance team. We can review your in house finance systems and procedures and review and implement strategies accordingly.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/ac3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Risk Management Consulting
                                    </h3>
                                    <p>
                                        In today’s fast paced and ever-changing regulatory environment, organizations across a spectrum of industries engage in activities to mitigate risk, but many times it’s not formal or sustainable, leaving the entity vulnerable to its consequences. The bottom line is – if you don’t have a risk assessment process, it is a threat to your organization. Similarly,  We work with clients from the healthcare, government, financial institution, and commercial sectors who engage us for risk management consulting services to identify risks and develop a plan to address and mitigate them – whether it’s regulatory or strategically driven. We are perpetually engaged with our clients and always up-to-date on industry trends and nuances, committed to responding promptly and proactively with critical information as it happens. This is how we deliver the
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/ac4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Tax Preparation & Planning Services
                                    </h3>
                                    <p>
                                        We get to know you and provide hands-on support wherever you are in your individual or business lifecycle. Whether the tax assistance you need is sophisticated, routine, strategic, or prompted by a major milestone event, we’ll focus on and thoroughly grasp all aspects of your unique situation. In addition, We collaborate with you to help you navigate potential obstacles, avoid costly mistakes, and benefit from our comprehensive advice. Our tax planning and tax preparation services are more than checking figures and computations on complex tax forms, schedules and supplements; or completing partnership, corporate, payroll, international, fiduciary, and gift tax returns. So,  We help you plan ahead for the future. Setting you up for success beyond the short-term.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>

        );
    }
}
export default AccountingTaxConsulting;
