import React from "react";


class Pricing extends React.Component {
    render() {
        return(

            <>
                <div className="page-content">
                    <div className="sect2-textpart">
                        <div className="demo">
                            <div className="section-title">
                                <h2 className="price-head sec2-h2">Software Development</h2>
                                <div className="container">
                                    <div className="row" style={{justifyContent: 'center'}}>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 87.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">WordPress Site
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 10,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Web Applications
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 100,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 37.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Desktop Applications
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 50,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sect2-textpart">
                        <div className="demo">
                            <div className="section-title">
                                <h2 className="price-head sec2-h2">Marketing Services</h2>
                                <div className="container">
                                    <div className="row" style={{justifyContent: 'center'}}>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 72.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Digital Marketing
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 3,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Email Marketing
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 4,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 70.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">SMS Marketing
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 5,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sect2-textpart">
                        <div className="demo">
                            <div className="section-title">
                                <h2 className="price-head sec2-h2">Hosting Services</h2>
                                <div className="container">
                                    <div className="row" style={{justifyContent: 'center'}}>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 87.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Domain Names
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 5,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 85.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Web Hosting
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 10,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 79.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Server Hosting
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    <h3 className="title">PKR 15,000</h3>
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sect2-textpart">
                        <div className="demo">
                            <div className="section-title">
                                <h2 className="price-head sec2-h2">Accounting Services</h2>
                                <div className="container">
                                    <div className="row" style={{justifyContent: 'center'}}>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 50.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Personal Tax Service
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    {/*<h3 className="title">PKR 10,000</h3>*/}
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="pricingTable">
                                                <svg x="0" y="0" viewBox="0 0 360 220">
                                                    <g>
                                                        <path fill="#000000" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                                        L0.732,193.75z"/>
                                                    </g>
                                                    <text transform="matrix(1 0 0 1 43.7256 116.2686)" fill="#fff"
                                                          fontSize="30px">Coporate Tax Service
                                                    </text>
                                                    {/*<text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff"*/}
                                                    {/*      fontSize="29.0829">.99*/}
                                                    {/*</text>*/}
                                                    {/*<text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"*/}
                                                    {/*      fontSize="15.4128">/Month*/}
                                                    {/*</text>*/}
                                                </svg>
                                                <div className="pricing-content">
                                                    {/*<h3 className="title">PKR 100,000</h3>*/}
                                                    {/*<ul className="pricing-content">*/}
                                                    {/*    <li><b>50GB</b> Disk Space</li>*/}
                                                    {/*    <li><b>50</b> Email Accounts</li>*/}
                                                    {/*    <li><b>50GB</b> Bandwidth</li>*/}
                                                    {/*    <li><b>10</b> Subdomains</li>*/}
                                                    {/*    <li><b>15</b> Domains</li>*/}
                                                    {/*</ul>*/}
                                                    <a href="#" className="pricingTable-signup">Subscribe Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default Pricing;
