import React from "react";
import { GiShoppingCart } from "react-icons/gi";
import { AiOutlineArrowRight } from "react-icons/ai";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { FaFacebookMessenger } from "react-icons/fa";
import img1 from '../img/img1.png'
import img2 from '../img/img2.png'
import img3 from '../img/img3.png'
import img4 from '../img/img4.png'
import img5 from '../img/img5.png'
import img6 from '../img/img6.png'
import img7 from '../img/img7.png'
import img8 from '../img/img8.png'
const state ={
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
}



class Home extends React.Component{

    render() {
        return(
            <div className="page-content">

                <section className="Section-1">
                    {/*<img src={"img/banner1.png"}  alt="image" />*/}
                    <div className="">
                        <div className="overlay-text">
                                <div className="row">
                                    <div className="">
                                        <div className="sect1-text" >
                                            <div className="">
                                                <h3 className="sect1-h3">
                                                    TOP SOFTWARE COMPANY IN PAKISTAN
                                                </h3>
                                            </div>
                                            <div className="">
                                                <h1 className="sect1-h1">
                                                    <span className="word">WELCOME TO</span>
                                                    <span className="word">FINANCIALSBOOK.COM</span>
                                                </h1>
                                            </div>
                                            <div className="">
                                                <p className="sect1-p">
                                                    Hire top teach talent & quickly scale your deleivery capacity
                                                </p>
                                            </div>
                                            <div className="">
                                                <button className="sec1-btn">
                                                    <a href="/ContactUS" style={{ color: 'inherit', textDecoration: 'none' }}>Request A Quote</a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                </div>
                            </div>
                    </div>
                </section>
                <div className="space-Section"/>

                {/* ======= Section 2======= */}
                <section className="Section-2">
                    <div class="container">

                        <div className="sect2-textpart">
                            <div className="section-title">
                                    <h2 className="sec2-h2">What we Offer</h2>
                                    <div className="sect2-subtitle">
                                        <h5 className="sect2-pg">
                                            Our Professional have 19 Years Experience in Development, Designing and Marketing.<br/>
                                            We Provide Perfect Branding Solution to Your Business.
                                        </h5>
                                    </div>
                                </div>
                        </div>
                        <div className="card-menu">
                            <div className="row" style={{marginBottom: '2rem'}}>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                            <div className="cover item-a">
                                                <div className="sect2-card1head">
                                                    <div className="sect2-icon1">
                                                        <a href="/Services/AccountingTaxConsulting">
                                                            <div>
                                                                <GiShoppingCart
                                                                    style={{
                                                                        color: 'rgb(64 100 215)',
                                                                        fontSize: '4rem',
                                                                        transform: 'scaleX(-1)',
                                                                    }}
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                        <h5 className="card-title fw-bold">Accounting & Tax Consulting</h5>
                                                        <p className="card-text">
                                                            We provide a range of assurance services, with the primary focus on the objective of assurance on the reliability and relevance of financial information
                                                            and a host of non-financial aspects, operations and procedures and on the appropriateness of controls.
                                                            Our services include: Accounting/ Bookkeeping Management Audit .
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-back">
                                                    <a href="/Services/AccountingTaxConsulting">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/Services/FinancialConsultingService">
                                                        <div>
                                                            <img
                                                                src="../img/h-1.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Financial Consulting</h5>
                                                    <p className="card-text">
                                                        We help companies to obtain their goals by strategic orientation with regard to strategic planning and many such other perspectives is what business advisory is all about. Services thus are directed towards: In other words, Strategy development to gain rewards of change which is accelerating exponentially and manage risks implicit in the venture.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/Services/FinancialConsultingService">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/Services/AuditingConsulting">
                                                        <div>
                                                            <img
                                                                src="../img/h-2.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Audit Consulting</h5>
                                                    <p className="card-text">
                                                        Our Internal Audit and Management Consulting team provides excellent service to our clients by meeting their needs, raising their level of risk and control knowledge, and increasing the efficiency of their operations and system of internal controls. Our Internal Audit & Management Consulting Team has the needed expertise and experience in process.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/Services/AuditingConsulting">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '2rem'}}>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/Services/ItConsulting">
                                                        <div>
                                                            <GiShoppingCart
                                                                style={{
                                                                    color: 'rgb(64 100 215)',
                                                                    fontSize: '4rem',
                                                                    transform: 'scaleX(-1)',
                                                                }}
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">IT Consulting</h5>
                                                    <p className="card-text">
                                                        Use the expertise and deep tech background of the best minds at Financialsbook.com to create a comprehensive IT strategy for a digital and technological transformation of your organization that goes in line with your business objectives.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/Services/ItConsulting">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/Services/WebDesigning">
                                                        <div>
                                                            <img
                                                                src="../img/h-3.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Web Designing</h5>
                                                    <p className="card-text">
                                                        Financialsbook.com is the pioneer best website design company & we are a creatively rich and technically outstanding web design team which providing best web designing services throughout the world.Our objective is to generate and maintain well-designed websites
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/Services/WebDesigning">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/services/GraphicDesigning">
                                                        <div>
                                                            <img
                                                                src="../img/h-4.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Graphic Designing</h5>
                                                    <p className="card-text">
                                                        With our pool of creative resources, we provide our clients with their preferred, high quality and creative graphic designs. our graphic designers produce aesthetically pleasing designs and we can perform any sort of customized tasks as required by the clients. Our expert provide a unique graphic solutions.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/services/GraphicDesigning">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '2rem'}}>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/Services/WebApplicationDevelopment">
                                                        <div>
                                                            <img
                                                                src="../img/h-5.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Web & Application Development</h5>
                                                    <p className="card-text">
                                                        Financialsbook.com is a web development agency that has top web developers in Pakistan. Tell us what you want to develop and we will bring it to fruition. From e-commerce to web development services, we do everything in between, Web Application Development & Web Development.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/Services/WebApplicationDevelopment">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/services/EnterpriseSoftwareDevelopment">
                                                        <div>
                                                            <img
                                                                src="../img/h-1.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Enterprise Software Development</h5>
                                                    <p className="card-text">
                                                        We provide full range of custom software development services to bring your ideas to life. in addition, Our turn key solutions are custom-designed to fit into your enterprise software ecosystem. Anything you can dream up, from a custom desktop application to a global app, we can build it.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/services/EnterpriseSoftwareDevelopment">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/services/DigitalMarketing/">
                                                        <div>
                                                            <img
                                                                src="../img/h-2.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Digital Marketing</h5>
                                                    <p className="card-text">
                                                        Our digital marketing services will take your business to the next level. We develop remarkable digital marketing strategies that drive greater traffic to your website, grow your business, and improve your brand awareness to potential customers.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/services/DigitalMarketing">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '2rem'}}>
                                <div className="col-md-4 col-sm-6 col-xs-12">

                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card h-100">
                                        <div className="cover item-a">
                                            <div className="sect2-card1head">
                                                <div className="sect2-icon1">
                                                    <a href="/service/DomainHostingService">
                                                        <div>
                                                            <GiShoppingCart
                                                                style={{
                                                                    color: 'rgb(64 100 215)',
                                                                    fontSize: '4rem',
                                                                    transform: 'scaleX(-1)',
                                                                }}
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body" style={{padding: '1rem 3rem'}}>
                                                    <h5 className="card-title fw-bold">Domain & Hosting Solutions</h5>
                                                    <p className="card-text">
                                                        If you want to have a website, you will start by choosing a domain name for it. A domain is the center of your internet identity. For business hosting we offer 100% Up-time guarantee. Similarly, We are among the very few companies offering such up-time email and website hosting in Pakistan
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <a href="/service/DomainHostingService">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* ======= Section 2======= */}

                    {/*/!* facebook Chat Popup *!/*/}
                    {/*<div className="facebook-popup">*/}
                    {/*    <a href="#" target="_blank">*/}
                    {/*        <div>*/}
                    {/*            <svg*/}
                    {/*                stroke="currentColor"*/}
                    {/*                fill="currentColor"*/}
                    {/*                strokeWidth={0}*/}
                    {/*                viewBox="0 0 512 512"*/}
                    {/*                height="1em"*/}
                    {/*                width="1em"*/}
                    {/*                xmlns="http://www.w3.org/2000/svg"*/}
                    {/*                style={{ color: "#0090fe" }}*/}
                    {/*            >*/}
                    {/*                <path className="facebook-icon" d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z" />*/}
                    {/*            </svg>*/}

                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</div>*/}




                {/* ======= Portfolio Section 3======= */}
                <section className="portfolio section-3">
                    <div className="container">
                        <div className="section-title">
                                <h2 className="sec3-h3">Our Portfolio</h2>
                            </div>
                        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
                                <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src={"img/port1.jpg"}  alt="image" />
                                        <div className="portfolio-info">
                                            <h4><a href="/Services/WebApplicationDevelopment">Web Development</a></h4>
                                            <p><AiOutlineArrowRight /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src={"img/port2.jpg"}  alt="image" />
                                        <div className="portfolio-info">
                                            <h4><a href="/services/DigitalMarketing">Digital Designing</a></h4>
                                            <p><AiOutlineArrowRight /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src={"img/port3.jpg"}  alt="image" />
                                        <div className="portfolio-info">
                                            <h4><a href="/Services/AccountingTaxConsulting">Accounting & Tax</a></h4>
                                            <p><AiOutlineArrowRight /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src={"img/port4.jpg"}  alt="image" />
                                        <div className="portfolio-info">
                                            <h4><a href="/services/GraphicDesigning">Social Media</a></h4>
                                            <p><AiOutlineArrowRight /></p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
                {/* End Portfolio Section */}

                {/* ======= Section 4======= */}
                <section className=" section-4">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="sec3-h3">Our Clients</h2>
                            <OwlCarousel className="owl-theme"
                                         items="3"
                                         autoplay={true}
                                         dots={false}
                                         rtl={true}
                                         loop={true}
                                         slideTransition={'linear'}
                                         margin={20}
                                         autoplaySpeed={10000}
                                         responsive={state.responsive}
                                 >


                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img1}
                                                        className="img-fluid img1"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img2}
                                                        className="img-fluid img2"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img3}
                                                        className="img-fluid img3"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img4}
                                                        className="img-fluid img4"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img5}
                                                        className="img-fluid img5"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img6}
                                                        className="img-fluid img6"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial-item rounded">
                                            <div className="d-flex mb-3">
                                                <div className="item">
                                                    <img
                                                        src={img7}
                                                        className="img-fluid img7"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                <div className="testimonial-item rounded">
                                    <div className="d-flex mb-3">
                                        <div className="item">
                                            <img
                                                src={img8}
                                                className="img-fluid img8"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                            </OwlCarousel>

                        </div>

                    </div>
                </section>
                {/* Section-4*/}

















            </div>
        );
    }

}
export default Home;
