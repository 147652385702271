import React from "react";

class BusinessRegistrationServices extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Business Registration Service</h3>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">

                                <div className="col-md-12">
                                    <div className="container">
                                        <h3>
                                            Our professional services are available for all type of registration of businesses with the following to offer:
                                        </h3>
                                        <p style={{margin: "30px 0px 10px", textAlign: "left" }}>
                                            In Business Registration Service-
                                        </p>
                                        <ul className="fsc-ul">
                                            <li>Type of Ownership Structure of the business entity.</li>
                                            <li>Type of Legal Structure of the business entity.</li>
                                            <li>Government policies which may have effect on the specific type of business structure.</li>
                                            <li>Formation of company (single member company, private limited company, public limited company and foreign company.</li>
                                            <li>Guidance on formation of subsidiary company, branch office, liaison office or joint venture.</li>
                                            <li>Guidance on the formation of partnership concern, trust or NGO.</li>
                                            <li>Guidance on all type of legal issues and court matters relating to the business.</li>
                                            <li>Registration of National Tax Number (NTN), Sales Tax Registration Number (STRN).</li>
                                            <li>Registration of patent, copyrights, trade mark or brand of business entity.</li>
                                            <li>Registration of WEBOC.</li>
                                            <li>Professional services to get Licenses, Permits and NOC for different regulatory bodies.</li>
                                            <li>Listing of Company in Stock Exchange.</li>
                                            <li>Legal services relating to drafting of business agreements.</li>
                                            <li>Advice on Employment legislation, employees old age benefits, pension funds, provident funds and social security contributions.</li>
                                            <li>Advice on all types of compliances &amp; planning’s under the various Corporate Laws.</li>
                                            <li>Obtaining approvals/permissions/sanctions /registrations from Regulatory Authorities.</li>
                                            <li>Guidance and assistance in identifying and optimizing various concessions, reliefs,
                                                exemptions in obtaining approvals/permissions/sanctions /registrations from Regulatory Authorities.</li>
                                            <li>Guidance and assistance in formulating various policies, procedures and manuals relating to Human Resource (HR), Accounts and Operations.</li>
                                            <li>Guidance and assistance in maintaining various statutory records and documentation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-12 order-1 order-md-2">
                                    <img src="/img/BRS-1.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            {/* Features Item */}
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>

        );
    }
}
export default BusinessRegistrationServices;
