import React from "react";

class SalesDistributionBusiness extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Sales & Distribution Business</h3>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            {/* Features Item */}
                            <div className="row justify-content-center features-item" data-aos="fade-up">
                                <div className="col-md-4">
                                    <ul className="fsc-ul">
                                        <li>Sales and Distribution (Retail & FMCG)</li>
                                        <li>Route to Market designing (RTM)</li>
                                        <li>Trade Marketing</li>
                                        <li>Trade Asset Management</li>
                                        <li>Retail Industry set up</li>
                                        <li>In store marketing</li>
                                        <li>OOH Marketing consultancy</li>
                                        <li>Visual merchandising</li>
                                        <li>Stylist training</li>
                                        <li>Order booker/salesman training</li>
                                    </ul>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="col-md-4 ">
                                    <ul className="fsc-ul">
                                        <li>Negotiation skills training</li>
                                        <li>KPIs management and tracking</li>
                                        <li>Supply chain management</li>
                                        <li>Warehouse controls and management</li>
                                        <li>Inventory control</li>
                                        <li>Health and safety setups in offices, warehouses and storage</li>
                                        <li>Budget allocation</li>
                                        <li>Brand, category and channel management</li>
                                        <li>Trade offers management</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>
        )
    }
}
export default SalesDistributionBusiness;
