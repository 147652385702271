import React from "react";

class DomainHostingService extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Find a Great Domain & Hosting With Us</h3>
                            </div>
                            <button className="contact-change6">
                                <a href="/ContactUS"> Let's purchase a domain</a>
                            </button>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/DHS1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Cheap Web Hosting In Pakistan</h3>
                                    <p>
                                        <a href="https://financialsbook.com/about-us/">Financialsbook.com</a>
                                        &nbsp;provides cheap web hosting in Pakistan, offer solid performance with extreme speed,
                                        support and security. Along with high-quality shared hosting, VPS, and dedicated servers,
                                        we also offer .pk domain registration. You are at right place if you are looking for domain
                                        registration.
                                        <a href="https://hosting.financialsbook.com/" target="_blank" rel="noopener">
                                            Financialsbook.com
                                        </a>
                                        &nbsp;is offering the latest TLDs like .com, .net, .org, .info, .us, .biz, .co, .co.uk at the industry’s
                                        lowest prices. Morevover, You can get many other value added services like domain privacy, name servers
                                        changing, ownership transfer, and even domain transfer out at no extra cost along with domain registration
                                        here at Financialsbook.com. We are offering cheap rates for .com&nbsp;
                                        <a href="https://hosting.financialsbook.com/products/domain-registration" target="_blank" rel="noopener">
                                            domain registration
                                        </a>
                                        &nbsp;in Pakistan at international level as well.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/DHS2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Web Hosting Services for All Web Needs
                                    </h3>
                                    <p style={{textAlign: 'Left'}}>
                                        In this competitive world of&nbsp;
                                        <a href="https://hosting.financialsbook.com/products/business" target="_blank" rel="noopener">hosting</a>
                                        &nbsp;providers, over 18 years of existence and advancement it’s substantial evidence of our
                                        exceptional services. We are one of the oldest web hosting companies in Pakistan. We always manage
                                        to grow our services and adapt technology to keep pace with modern world. Due to our promising
                                        commitment 80% of new customers are referred by our happy clients. Our premium hosting includes
                                        4000 businesses from 80 countries.
                                    </p>
                                    <p>
                                        We are proud to offer the best&nbsp;web hosting solution at low cost with 99.9%
                                        uptime. Similarly, Affordable hosting plans are offered in budget hosting range, while our
                                        <a href="https://hosting.financialsbook.com/products/business">business hosting</a>
                                        and
                                        <a href="https://hosting.financialsbook.com/products/wordpress">WordPress</a>
                                        hosting brings more reliability and the top-class hosting features.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/DHS3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Cheap Domain Name Registration
                                    </h3>
                                    <p>
                                        The Domain Registration Experts at your Service. You have got a
                                        <a href="https://financialsbook.com/business-registration-service/"> business</a>
                                        idea at you are soon going to start online – All you need now is a Domain
                                        Registration in Pakistan and a superior web hosting in Pakistan that execute
                                        your business idea. Here at Financialsbook.com we have got thousands of&nbsp;
                                        <a href="https://hosting.financialsbook.com/products/domain-transfer" target="_blank" rel="noopener">
                                            domain extensions</a>
                                        &nbsp;and millions of domain names to choose from. Above all, With loads of
                                        features and tools we are offering domain at cheap and
                                        unbeatable price..! So the perfect name is just a quick search away.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/DHS4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Register Domain Now
                                    </h3>
                                    <p>Domain names are being registered very fast. A lot of investors are registering domains,
                                        and they will register any good domain name that they will not find. As of today domain
                                        names are much cheaper than before. You can get a name at an average for 8 USD. In other
                                        words, if you talk about
                                        <a href="https://hosting.financialsbook.com/domains/bulk-domain-search?plid=563548">domain names</a>
                                        Pakistan you will get the cheapest domain names, with&nbsp;
                                        <a href="https://hosting.financialsbook.com/products/cpanel" target="_blank" rel="noopener">control panel</a>
                                        &nbsp;and everything.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default DomainHostingService;