import React from "react";

class ContactUs extends React.Component {
    render() {
        return(

            <>
                <div className="page-content">

                        {/* Section-1 */}
                        <section className="section-Contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '8rem'}}>
                                        <div className="section-title">
                                            <h2 className="" style={{color: 'white'}}>Contact Us</h2>
                                        </div>
                                        <div className="section_title_all text-center">
                                            <p className="section_subtitle mx-auto text-muted" style={{fontSize: '20px'}}>
                                                Contact for Premium Business Services
                                            </p>
                                            <div className="">
                                                <i className=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Section-1 */}



                        <section className="section">
                                <div className="container">
                                    <div className="section-title">
                                        <h2 className="sec3-h3">Let's get in touch</h2>
                                    </div>
                                    <div className="container container-contact-form">
                                <span className="big-circle" />
                                <img src="img/shape.png" className="square" alt="" />
                                <div className="form">
                                    <div className="contact-info">
                                        <h3 className="title">Request a free consultation</h3>
                                        <p className="text">
                                            We appreciate your business and guarantee a response within 24 Business hours. You can ping us using below form. Our team will contact you within the shortest possible time.
                                            <br/>
                                            <b style={{color: 'red'}}>
                                                Please do give us a call or drop an email for any queries. we would love to hear from you.
                                            </b>
                                        </p>

                                        <div className="info">

                                            <h5 className="adrs-txt">PAKISTAN ADDRESS:</h5>
                                            <div className="information">
                                                <p style={{marginBottom: "0px", textAlign: "left"}}>
                                                    <i className="fas fa-map-marker-alt" />&nbsp;&nbsp;&nbsp;
                                                    <strong>Faisalabad:</strong>
                                                    &nbsp; Building 9 Zainab Masjid Road &nbsp; Muhammadia Colony Madina Town,
                                                    Faisalabad, Punjab Pakistan <br/>
                                                    <i className="fas fa-map-marker-alt" />&nbsp;&nbsp;&nbsp;
                                                    <strong>Phone:</strong>
                                                    &nbsp; (+92)300-6658-511
                                                </p>
                                            </div>
                                            <h5 className="adrs-txt">UAE ADDRESS:</h5>
                                            <div className="information">
                                                <p style={{marginBottom: "0px", textAlign: "left"}}>
                                                    <i className="fas fa-map-marker-alt" />&nbsp;&nbsp;&nbsp;
                                                    <strong>Dubai:</strong>
                                                    &nbsp;  NASAB, Wadi Al Safa 3, Dubai <br/>
                                                    <i className="fas fa-map-marker-alt" />&nbsp;&nbsp;&nbsp;
                                                    <strong>Phone:</strong>
                                                    &nbsp; (+971) 56 333 2680
                                                </p>
                                            </div>
                                            <div className="information">
                                                <i className="fas fa-envelope" /> &nbsp; &nbsp;
                                                <p style={{marginBottom: "0px"}}>sales@financialsbook.com</p>
                                            </div>
                                            <div className="information"> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <p style={{marginBottom: "0px"}}>info@alifaa.org'</p>
                                            </div>
                                            <div className="information">
                                                <i className="fas fa-phone" />
                                                &nbsp;&nbsp;
                                                <p style={{marginBottom: "0px"}}>(+92)300-6658-511</p>
                                            </div>
                                        </div>
                                        <div className="social-media">
                                            <p style={{textAlign: "justify"}} >Connect with us :</p>
                                            <div className="social-icons">
                                                <a href="https://www.facebook.com/financialsbook/" target="_blank">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="https://www.instagram.com/financialsbook/" target="_blank" >
                                                    <i className="fab fa-instagram" />
                                                </a>
                                                <a href="https://www.linkedin.com/company/financialsbook/" target="_blank" >
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact-form">
                                        <span className="circle one" />
                                        <span className="circle two" />
                                        <form action="mailto:Sales@financialsbook.com" autoComplete="off">
                                            <h3 className="title">Contact us</h3>
                                            <div className="input-container">
                                                <input type="text" name="name" className="input" />
                                                <label htmlFor="">Username</label>
                                                <span>Username</span>
                                            </div>
                                            <div className="input-container">
                                                <input type="email" name="email" className="input" />
                                                <label htmlFor="">Email</label>
                                                <span>Email</span>
                                            </div>
                                            <div className="input-container">
                                                <input type="tel" name="phone" className="input" />
                                                <label htmlFor="">Phone</label>
                                                <span>Phone</span>
                                            </div>
                                            <div className="input-container textarea">
                                                <textarea name="message" className="input" defaultValue={""} />
                                                <label htmlFor="">Message</label>
                                                <span>Message</span>
                                            </div>
                                            <input type="submit" defaultValue="Send" className="contact-sub-btn" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                                </div>
                        </section>
                </div>
            </>

        );
    }
}
export default ContactUs;
