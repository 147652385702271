import React from "react";

class AuditingConsulting extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>FinancialsBook Give you a Excellent Auditing Consulting</h3>
                                <button className="contact-change6">
                                    <a href="/ContactUS"> Lets Consulting With Us</a>
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/AUC1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Internal Audit &amp; System Control Review</h3>
                                    <p>When carefully designed and properly implemented, a strategic internal audit framework can unlock
                                        significant value for an organization while also aligning risk management, controls and compliance with
                                        mission, vision and values. As a recognized and trusted leader in internal audit services, Financialsbook.com
                                        possesses the depth and breadth of experience necessary to maximize your internal audit function’s efficiency
                                        and value. Our sophisticated and comprehensive internal audit services are custom-designed to meet each
                                        client’s unique needs. In addition, we can help you implement an internal audit framework that boosts
                                        productivity and efficiency, addresses current and potential
                                        problems throughout your enterprise, mitigates redundant processes, and highlights potential improvements.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/AUC2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Information Technology Audit
                                    </h3>
                                    <p>Information Technology (IT) is becoming increasingly important to the business strategy,
                                        operations and internal audit of most organizations today. An increased dependency on
                                        technology to deliver meaningful benefits to an organization can raise additional issues of
                                        security, integrity and control. Therefore, we understand how vital it is to manage these
                                        business and regulatory concerns. Our&nbsp;
                                        <a href="https://financialsbook.com/it-consulting/" target="_blank" rel="noopener">IT Auditing Consulting</a>
                                        &nbsp;&amp; Risk Advisory Services Group can help protect your organization’s information systems,
                                        ensure compliance with regulatory requirements
                                        and provide insights to leverage IT controls to reduce costs and gain competitive advantage.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/AUC3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Management Consulting
                                    </h3>
                                    <p>Financialsbook.com specializes in internal and management audits, which are primarily
                                        conducted in order to provide your company’s management with a clear, comprehensive
                                        and unbiased analysis of the functional efficiency of the organization and to suggest
                                        possible areas for improvements. Moreover, strongly believe in internal and management
                                        audits being a value addition exercise rather than a mere compliance exercise.&nbsp;
                                        <a href="https://www.facebook.com/financialsbook/https://www.facebook.com/financialsbook/" target="_blank" rel="noopener">Financialsbook.com</a>
                                        &nbsp;firmly believes in providing value added services through highest levels of professional
                                        competency and integrity to its clients. Above all, Our internal and management audit services are
                                        designed to suit individual client specific needs. So, It can provide you assistance in managing
                                        your key business concerns, be it achieving your strategic business goals, meeting operational
                                        challenges, complying with regulatory norms or managing reporting requirements.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>
        )

    }
}
export default AuditingConsulting;
