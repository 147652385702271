import React from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

class Header extends React.Component {
    render() {
        return(
            <header className="header">


                <Navbar expand="lg">
                    <div class="container header">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <a href="/">
                                <img src={"../img/fblogopng.png"} width="auto" height="auto"/>
                            </a>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav " className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <Nav className="mx-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/AboutUs">About</Nav.Link>
                                <NavDropdown title="Our Services" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/Services/WebApplicationDevelopment">
                                        Web Application & Development
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/AccountingTaxConsulting">
                                        Accounting & Tax Consulting
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/ItConsulting">
                                        IT Consulting
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/FinancialConsultingService">
                                        Financial Consulting Service
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/AuditingConsulting">
                                        Audit Consulting
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/WebDesigning">
                                        Web Designing
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/GraphicDesigning">
                                        Graphic Designing
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/EnterpriseSoftwareDevelopment">
                                        Enterprise Software Development
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/DigitalMarketing">
                                        Digital Marketing
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/DomainHostingService">
                                        Doman & Hosting Services
                                    </NavDropdown.Item>
                                    <NavDropdown title="Business Setup & Training" id="basic-nav-dropdown" style={{fontWeight: "400", padding: "0px 7px"}} className="dropdown-menu-toggle" >
                                        <NavDropdown.Item className="menu-side" href="/Services/BusinessRegistrationServices">
                                            Business Registration Service
                                        </NavDropdown.Item>
                                        <NavDropdown.Item className="menu-side" href="/Services/SalesDistributionBusiness">
                                            Sales & Distribution Business
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown.Item href="/Pricing">
                                        Pricing
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/Services/Support">
                                        Support
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Our Products" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="https://onesuite.work/" target="_blank">
                                        OneSuite.work
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#">
                                        Tokrii.com
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Our Offices" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="http://alifaa.org/uae/alifaa" target="_blank">
                                        UAE
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="http://alifaa.org/uk/alifaa" target="_blank">
                                         UK
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/Partners">Partners</Nav.Link>
                                <Nav.Link href="/Blog">Blog</Nav.Link>
                                <Nav.Link href="/ContactUS">Contact</Nav.Link>
                            </Nav>
                            <div className="nav-btn">
                                <Button className="button sec1-btn col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <a href="/ContactUS" style={{ color: 'inherit', textDecoration: 'none' }}>Request A Quote</a>
                                </Button>
                            </div>

                        </Navbar.Collapse>
                    </div>
                </Navbar>

            </header>
        );
    }
}
export default Header;
