import React from "react";

class FinancialConsultingService extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Trusted Financial Executives To Meet Your Exact Needs.</h3>
                                <button className="contact-change6">
                                    <a href="/ContactUS"> Lets Consulting With Us</a>
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/FCS1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Business Management and Internal Controls review</h3>
                                    <p style={{textAlign: 'left'}} >We conduct Market Feasibility Study and Financial Feasibility Study to analyze the feasibility of your business investment in the current market scenario and make cash flow forecasts and projections. Moreover, We study the latest market trends, existing competition, client needs, new and upcoming products, and prices as a part of our market feasibility study. We help companies to obtain their goals by strategic orientation with regard to strategic planning and many such other perspectives is what business advisory is all about. In other words, An internal control system provides several advantages for organizations, which collectively have the ability to increase shareholder value.  Some of these advantages include</p>
                                    <ul className="fsc-ul">
                                        <li>Enabling growth, rapidly responding to changes in the marketplace.</li>
                                        <li>Configuring the Supply Network adapted to business needs.</li>
                                        <li>Accelerating time-to-market.</li>
                                        <li>Gaining end-to-end visibility.</li>
                                        <li>Managing short term violation</li>
                                    </ul>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/FCS2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Bookkeeping And Business Process Outsourcing
                                    </h3>
                                    <p >
                                        At&nbsp;
                                        <a href="https://www.facebook.com/search/top/?q=financialsbook.com" target="_blank" rel="noopener">
                                            Financialsbook.com,
                                        </a>
                                        &nbsp;we create ease for our clients by taking away all their accounting hassles. While keeping updated business records pertaining to all financial transactions and ensuring proactive reporting, our bookkeeping service enables clients to make informed decisions at the right time. The most reliable BookKeeping services in Pakistan.Our experienced resource supervised by Manager Finance and Accounts who is further supervised by Partner&nbsp;<a href="https://financialsbook.com/accounting-tax-consulting/" target="_blank" rel="noopener">Finance &amp; Accounting Services</a> (20 years of experience) is there based at the client side to provide all needed support to streamline financial records. With its risk reducing benefit, our bookkeeping service (Finest BookKeeping services in Pakistan).helps eliminate any probability of monetary embezzlement otherwise suffered by the client. Besides, proactive reporting enables client to make smart business decisions confidently. At Invensis, our Business Process Outsourcing (BPO) services are focused towards delivering outstanding results for our clients. We adapt our outsourcing services to cater to the specific needs of your business and assist you in achieving your business goals. Above all,&nbsp; You also get the advantage of cost-effective services, that span back office solutions, customer care outsourcing,
                                        Financial consulting service and Accounting (F&amp;A), and information technology (IT).
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/FCS3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Supply Chain Management and Implementation</h3>
                                    <p style={{textAlign: 'left'}} >Financialsbook.com
                                        Strategy’s supply chain financial consulting service provide the experience, capabilities and insight to help clients create long-term profitability and shareholder value through developing dynamic operations and digital supply networks. Our service offerings are truly differentiated in the marketplace because they embody all the capabilities needed to drive an end-to-end, value based approach. Therefore, We know strategy, industries, business functions, and technology. When teaming with Accenture, clients have access to a dedicated core of thousands of deeply experienced and skilled resources in supply chain strategy, innovation and process consulting and technology capabilities around the globe. Furthermore, We offers a variety of services to address all aspects of a supply chain transformation journey, which encompasses strategy, operating model, process and technology enablement: <span style={{fontSize: '14px'}}>We work with our clients
                                        across a broad range of industries to address key business issues such as:</span></p>
                                    <ul className="fsc-ul">
                                        <li>Enabling growth, rapidly responding to changes in the marketplace.</li>
                                        <li>Configuring the Supply Network adapted to business needs.</li>
                                        <li>Accelerating time-to-market.</li>
                                        <li>Gaining end-to-end visibility.</li>
                                        <li>Managing short term violation</li>
                                    </ul>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/FCS4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Strategic Financial Planning and Management
                                    </h3>
                                    <p>Our highly experienced financial planners will ask the right questions, and then listen carefully to understand every aspect of your financial situation, in conjunction with your specific objectives, needs, attitude to risk and lifestyle. Using this information, we will formulate a tailored, strategic financial plan that is designed to give you clarity and direction for your financial journey. Our innovative advice is based on researching a wide variety of tax-efficient wrappers and investment frameworks to find the most appropriate solutions that suit your needs.
                                        These may include investment services, retirement planning, tax structuring and protection strategies.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>
        )
    }
}
export default FinancialConsultingService;
