import React from "react";

class Partners extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">

                    <section className="">
                        <div className="container">
                            <div className="section-title">
                                <h2 className="sec3-h3">Training Development Partner</h2>
                                <div className="row">
                                    <div className="col-md-12 align-self-center text-center">
                                        <a href="https://www.accaglobal.com/uk/en/help/approved-employer-details.html?" target="_blank">
                                            <img src="../img/pt1.png" className="img-fluid" alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}
export default Partners;
