import React from "react";

class GraphicDesigning extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Good Design Brings Good Business</h3>
                            </div>
                            <button className="contact-change6">
                                <a href="/ContactUS"> Lets Develop Your Website</a>
                            </button>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/GD1.JPG" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Logo &amp; Identity Design</h3>
                                    <p>The logo is the face of your Brand. We have the most talented and experienced logo designers, who approach every logo design project with an inspired dedication with a complete thought process. Being a focus based quality branding agency in Pakistan. Furthermore, we have been designing
                                        logos and brand identities for many reputed companies and that’s something we are good at.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/GD2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Print Design
                                    </h3>
                                    <p>Your printed marketing material can make a significant impact on your company’s image. Similarly, We provide quality print design services to make sure that your business cards, letterheads, envelopes, brochures, and other marketing materials
                                        stand out in every sales meeting, face-to-face encounters and direct mailings.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/GD3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Web Design(UX/UI)
                                    </h3>
                                    <p>From B2B to B2C websites,&nbsp;
                                        <a href="https://financialsbook.com/" target="_blank" rel="noopener">Financialsbook.com</a>
                                        &nbsp; provides quality web, UI and &nbsp;
                                        <a href="https://hosting.financialsbook.com/" target="_blank" rel="noopener">UX design services</a>
                                        &nbsp;
                                        that are pixel perfect and uses a responsive design approach to make it work on all devices and make your website stand out from your competitors. In addition, Our website graphic&nbsp; design services
                                        can help you boost more revenue, greater brand engagement and higher conversions with measurable results.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/GD4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Brand & Stationery Design
                                    </h3>
                                    <p>Being one of the leading E-commerce web design company in Pakistan, we know how to create
                                        E-commerce websites that actually sell your products. In other words, As an E-commerce web
                                        development company, we help you grow your business online by building your own online store.
                                        Besides offering E-commerce development services we also provide support, we also provide support
                                        for local payment gateway integration, logistics management solution and self-service CMS to monitor
                                        your sales, add new products and more. So, We keep ourselves updated with the latest trends of Graphic
                                        Designing around the globe and we understand its needs and also what value we can add to these trends.
                                        Thus, we are serving the global market with our best solutions of Graphic Designing and
                                        Multimedia as per your demand and paving an online way to your success in business.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default GraphicDesigning;
