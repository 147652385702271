import React, { useState } from "react";
import CountUp from "react-countup";
import { AiFillHeart } from "react-icons/ai";
import { BsCupHotFill } from "react-icons/bs";
import { LiaAwardSolid } from "react-icons/lia";
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ScrollTrigger from "react-scroll-trigger";
import OwlCarousel from "react-owl-carousel";
import affiliate1 from '../img/affiliate1.png'
import affiliate2 from '../img/affiliate2.png'
import affiliate3 from '../img/affiliate3.png'
import affiliate4 from '../img/affiliate4.png'
import affiliate5 from '../img/affiliate5.png'
import { BsFillTelephonePlusFill } from "react-icons/bs";


    // const [counterStage, setCounterState] = useState(false)

class AboutUs extends React.Component {
    render() {
        return(

            <>
                <ScrollTrigger>
                    <div className="page-content">

                        {/* =======  Section-1 ======= */}
                        <section className="section-about">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                        <div className="section-title">
                                            <h2 className="" style={{color: 'white'}}>About Us</h2>
                                        </div>
                                        <div className="section_title_all text-center">
                                            <p className="section_subtitle mx-auto text-muted">
                                                FinancialsBook is firm creating the Customized Softwares & Apps since
                                                last two decades.
                                                The firm is providing services associated with outsourced Accounting,
                                                Financial & Tax advisory,
                                                Online HR & Payroll Process & IT Consulting Services.
                                            </p>
                                            <div className="">
                                                <i className=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* =======  Section-1 ======= */}

                        {/* =======  Section-2 ======= */}
                        <section className="section-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="counter">
                                            <div className="counter-icon">
                                                <i className="fa fa-globe"/>
                                            </div>
                                            <h3>Projects Completed</h3>
                                            <span className="counter-value">
                                                    <span className="counter-value">
                                                        {/*{ counterStage &&*/}
                                                            <CountUp
                                                                start={0}
                                                                end={100}
                                                                duration={1}
                                                                delay={0}
                                                            />
                                                        {/*}*/}
                                                    </span>
                                                </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="counter pink">
                                            <div className="counter-icon">
                                                <div>
                                                    <AiFillHeart
                                                        style={{
                                                            color: '#ffffff',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <h3>Happy Clients</h3>
                                            <span className="counter-value">
                                                    <CountUp
                                                        start={0}
                                                        end={102}
                                                        duration={1}
                                                        delay={0}
                                                    />
                                                </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="counter orange">
                                            <div className="counter-icon">
                                                <div>
                                                    <BsCupHotFill
                                                        style={{
                                                            color: '#ffffff',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <h3>Cup of Coffees</h3>
                                            <span className="counter-value">
                                                    <CountUp
                                                        start={0}
                                                        end={156}
                                                        duration={1}
                                                        delay={0}
                                                    />
                                                    K+
                                                </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="counter purple">
                                            <div className="counter-icon">
                                                <div>
                                                    <LiaAwardSolid
                                                        style={{
                                                            color: '#ffffff',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <h3>Awards</h3>
                                            <span className="counter-value">
                                                    <CountUp
                                                        start={0}
                                                        end={28}
                                                        duration={1}
                                                        delay={0}
                                                    />
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* =======  Section-2 ======= */}

                        {/* =======  Section-3 ======= */}
                        <section className="section-3 features">
                            <div className="container">
                                <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                    <div className="col-md-5">
                                        <img src="/img/ab-1.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <h3>
                                            Who We Are?
                                        </h3>
                                        <p>
                                            <a href="https://www.instagram.com/financialsbook/" target="_blank" style={{fontSize: '14px', backgroundColor: 'rgb(246, 246, 246)'}}>Financialsbook.com</a>
                                            &nbsp;&nbsp; is a well-reputed digital marketing, IT solutions and financial consulting agency whose aim is to assist companies in accomplishing considerable development and beneficial success. We have professionals who are devoted to their work and profession. They are trained to get the customer’s satisfaction.
                                        </p>
                                    </div>
                                </div>
                                {/* Features Item */}
                                <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                    <div className="col-md-5 order-1 order-md-2">
                                        <img src="/img/ab-2.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-7 order-2 order-md-1">
                                        <h3>Our Mission</h3>
                                        <p>
                                            Our mission is to provide best in class, creative, unique Software and Accounting and Tax Auditing Solutions that can solve all our client’s business problems that they face on day to day bases. To be the trusted partner of our clients, helping them to about us  enhance their business performance by providing them with innovative solutions
                                        </p>
                                    </div>
                                </div>
                                {/* Features Item */}
                                <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                    <div className="col-md-5">
                                        <img src="/img/ac3.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <h3>
                                            What We Do?
                                        </h3>
                                        <p>
                                            We offer the design and building services of applications from the initial sketch to the final production and running. We provide a range of assurance services, with the primary focus on the objective of assurance on the reliability and relevance of financial information and a host of non-financial aspects.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*=======  Section-3 ======= */}

                        {/* =======  Section-4 ======= */}
                        <section className="section">
                            <div className="container-fluid service py-5">
                                <div className="container">
                                    <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                                        <h2 className=" mb-4 sec2-h2">
                                            Our History
                                        </h2>
                                        <p className="mb-0">
                                            Since its inception, it has grown and progressed to be at the center for
                                            about us
                                            stage of information and technology, providing effective computing
                                            strategies and solutions to Private and Government Organizations.
                                            Financialsbook.com
                                            has played a major role in some of the largest IT projects in the country.
                                            Internationally, the Company has established itself as a key player in the
                                            critical market
                                            segments of different countries, providing services & products to a growing
                                            list of corporate clients. Our diverse expertise extends beyond
                                            deployment to provide operational, maintenance, support and business
                                            outsourcing services.
                                            Our Technology Specialists have years of experience delivering successful
                                            solutions based on Microsoft, Oracle, PHP, ASP, 3D and Java-based
                                            technologies..
                                        </p>
                                    </div>
                                    <div className="section-title wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="sub-style">
                                            <h4 className="sub-title px-3 mb-0">Our 6-D Process</h4>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-5">
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services settings">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Discover</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Exploration</strong>
                                                        &nbsp;  – We seek to understand where you are and where you wish to go.  We take time to understand your marketing goals and objectives.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services speedup">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Define</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Web Strategy</strong>
                                                        &nbsp;  – We start out by defining the necessary requirements for your project. Specific deliverables are agreed upon and the web strategy is put in place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services privacy">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Design</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Get Creative</strong>
                                                        &nbsp;  – After the goals, specifications and requirements have been assessed we move into designing the Website’s architecture. We focus on usability, search ability and simplicity.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-5">
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services backups">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Develop</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Time to Build</strong>
                                                        &nbsp;   – In the development stage, the finalized design files are handed off to the development team to implement the code and back-end systems to make the Website interactive.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services ssl">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Deploy</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Site Launch</strong>
                                                        &nbsp;  – we make sure your team understands the CMS structure, technology, and Website functionality. We train you on site maintenance to ensure a smooth transition. The site is monitored for 30 days after launch to guarantee all aspects are working properly.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-8">
                                            <div className="box">
                                                <div className="our-services database">
                                                    <div className="icon">

                                                    </div>
                                                    <h4 className="about-h4-subtxt">Deliver</h4>
                                                    <p style={{textAlign: 'left'}}>
                                                        <strong>Project Delivery Process</strong>
                                                        &nbsp;   -Our IT Project Delivery Services team specialises in the skill sets that compliment core Software Engineering. Great Project Managers, Business Analysts and Quality Assurance Testers are all essential to successful project outcomes.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* =======  Section-4 ======= */}



                        {/* ======= Our Clients Section ======= */}
                        <section className=" section-5">
                            <div className="container">
                                <div className="section-title">
                                    <h2 className="sec3-h3">Our Clients</h2>
                                </div>
                                    <div className="clients-slider swiper">
                                        <div className="swiper-wrapper align-items-center">
                                            <OwlCarousel  className="owl-theme"
                                                          items="3"
                                                          autoplay={true}
                                                          dots={false}
                                                          rtl={true}
                                                          loop={true}
                                                          slideTransition={'linear'}
                                                          margin={20}
                                                          Speed={600}

                                            >

                                            <div className="swiper-slide">
                                                <img
                                                    src={affiliate1}
                                                    className="img-fluid affi1"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="swiper-slide">
                                                <img
                                                    src={affiliate2}
                                                    className="img-fluid affi2"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="swiper-slide">
                                                <img
                                                    src={affiliate3}
                                                    className="img-fluid affi3"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="swiper-slide">
                                                <img
                                                    src={affiliate4}
                                                    className="img-fluid affi4"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="swiper-slide">
                                                <img
                                                    src={affiliate5}
                                                    className="img-fluid affi5"
                                                    alt=""
                                                />
                                            </div>

                                    </OwlCarousel>
                                        </div>
                                        <div className="swiper-pagination" />
                                    </div>
                                    </div>
                        </section>
                        {/* End Our Clients Section */}

                        <section className="content4 supplym5 cid-tpT3uiPq7J" id="content4-8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row card">
                                            <div className="col-12 col-lg-6">
                                                <div className="subtitle-wrapper">
                                                    <h3 className="mbr-section-subtitle mbr-fonts-style display-7">
                                                        Why Choose FinancialsBook?
                                                    </h3>
                                                </div>
                                                <h2 className="mbr-section-title mbr-fonts-style display-5">
                                                    Would you like to start a project with us?
                                                </h2>
                                                <div className="block m-5">
                                                    <div className="d-flex justify-content-center h-100 gap">
                                                        <a className="button-btn-extra" data-abc="true" href="/ContactUS">
                                                            <div>
                                                                <BsFillTelephonePlusFill
                                                                    style={{
                                                                        color: '#ffffff',
                                                                        marginRight: '10px'
                                                                    }}
                                                                />
                                                            </div>
                                                            (+92)300-6658-511
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="text-wrapper">
                                                    <Accordion>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Expertise
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    Since 2008 we have built a formidable reputation in the field of server management with expertise in core technologies.Our Support scope formulates both application based support and server management together.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Affordable Pricing
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    With a range of Outsourced Web Hosting Support Plans and Outsourced Customer Support Service Plans on offer, we ensure that you find one that exactly suits your requirements.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    24/7 Support
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    Our techs will work 24x7x365. Your Helpdesk is observed round-the-clock. We have backup techs for your server management.Our flexible and scalable staffing solutions also ensure reliable support.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Quality
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    We have a quality management system for our service. This department will ensure the quality of our support staff’s reply. Our support engineers act very fast and effectively in the much needed situations.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Experience
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    Our team includes highly qualified and experienced administrators. We only hire Engineering graduates (4 year graduation with IT/ITES professional degree ) as our technicians.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Employee Hierarchy
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <p class="FAQ ans">
                                                                    Our company is maintaining a good employee hierarchy. It starts from tech -> Team Leader -> Manager. So it is so easy and suitable for managing your team.
                                                                </p>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </ScrollTrigger>



            </>

        );
    }
}
export default AboutUs;
