import React from "react";

class WebDesigning extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>We Are a Top Web Design Company In Pakistan</h3>
                            </div>
                            <button className="contact-change6">
                                <a href="/ContactUS"> Lets Develop Your Website</a>
                            </button>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/WBD1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Responsive Web Design Services
                                    </h3>
                                    <p><span className="s2">Financialsbook.com focuses on not only providing beautiful design, but on</span>
                                        &nbsp;
                                        <span className="s2">layouts that give your clients the information they’re looking for – the oh-so-important first impression of your organization or business, the services you provide, or about the book you just published. A large portion of your customers will be accessing your website from their phone. Our web designs are created to look great on many types of devices.<br />
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/WBD2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Mobile Friendly Websites Design</h3>
                                    <p>When it comes to web design and development, it’s pretty important to work with a company that knows how to get things done. The world has gone mobile—more than 50% of all website queries happen on a mobile device. Can your website handle it? We design your website with mobile in mind—optimized and coded for the most up-to-date mobile SEO practices.
                                        Our web design services are 100% responsive i.e. they will adapt to all devices from mobile to web</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/WBD3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Landing Page Design &amp; Optimization
                                    </h3>
                                    <p>What does it take for a landing page to convert? We know the answer. With the right components and copy, we can improve your site’s performance.&nbsp;<a href="https://financialsbook.com/" target="_blank">Financialsbook.com</a>&nbsp;creates original, hand coded websites that can be easily viewed on any device, as the era of smartphones requires. The scalable templates and smooth rendering ensure the satisfaction of your visitors. As a mobile website
                                        design company, we ensure the use of the latest trends and technologies to make this possible.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/WBD4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        CMS Website Design Services
                                    </h3>
                                    <p>You get a website unique for your company—either completely custom or designed from the best-converting templates. Depending on the complexity, we usually create websites using WordPress.
                                        <span style={{fontSize: '16px'}}>A CMS web presence allows you to manage your web site even if you have no technical knowledge, experience with HTML or web site design. It also reduces the calls to your web design agency or IT department for changes to the web site. CMS reduces the time required to publish, allowing you to
                                        get your content on-line faster. This is a very important issue for any professional web site.</span></p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/WBD5.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Custom Website Design Services
                                    </h3>
                                    <p><a style={{fontSize: '14px', backgroundColor: '#f6f6f6'}} href="https://www.instagram.com/financialsbook/" target="_blank" rel="noopener">Financialsbook.com</a>&nbsp;offers fully Customized Website Design Services so that we can help bring your design vision to life. We make Website Designs that are exactly tailored as per your business’ requirements so your website is optimized for your targeted audience ensuring success. If you are super creative and you’re brain is flooded with inspiring ideas for your custom web design, our team is all set to guide and work
                                        with you so that together we are able to build upon your ideas and create a masterpiece.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default WebDesigning;
