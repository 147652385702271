import React from "react";

class Blog extends React.Component {
    render() {
        return(

            <>
                <div className="page-content">

                    {/* Section-1 */}
                    <main id="main">
                            {/* ======= Blog Section ======= */}
                            <section id="blog" className="blog">
                                <div className="container" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-lg-6 entries">
                                            <article className="entry-front">
                                                <div className="entry-img">
                                                    <img
                                                        src="img/blog-1.jpeg"
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <h2 className="entry-title">
                                                    <a href="blog-single.html">
                                                        Know Your Tax Rate
                                                    </a>
                                                </h2>
                                                <div className="entry-meta">
                                                    <ul>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-user"></i>
                                                            <a href="blog-single.html">Admin</a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="bi bi-clock" />{" "}
                                                            <a href="blog-single.html">
                                                                <time dateTime="2020-01-01">Sep, 6</time>
                                                            </a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-comment"></i>
                                                            <a href="blog-single.html">Comments Off</a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-folder"></i>
                                                            <a href="blog-single.html">Categories : &nbsp;
                                                                <a href="https://financialsbook.com/category/tax/" rel="category tag" style={{color: "red"}}>tax</a>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="entry-content">
                                                    <p>

                                                        SALARIED INDIVIDUALS NON SALARIED & AOPs NON SALARIED & AOPs INCOME FROM PROPERTY IMMOVABLE PROPERTY MINIMUM TAX DISPOSAL OF SECURITIES DISCHARGE OF TAX TAX RATES FOR SALARIED INDIVIDUALS [Division I, Part of the First Schedule] S.No. Taxable Income Rate of tax 1 Upto Rs.600,000 0% 2 Rs. 600,001 to Rs.
                                                    </p>
                                                    <div className="read-more">
                                                        <a href="/blog/KnowYourTaxRate">Read More</a>
                                                    </div>
                                                </div>
                                            </article>
                                            {/* End blog entry */}
                                        </div>
                                        <div className="col-lg-6 entries">
                                            <article className="entry-front">
                                                <div className="entry-img">
                                                    <img
                                                        src="img/blog-1.jpeg"
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <h2 className="entry-title">
                                                    <a href="blog-single.html">
                                                        Income Tax Return Deadline - 30th september, 2020
                                                    </a>
                                                </h2>
                                                <div className="entry-meta">
                                                    <ul>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-user"></i>
                                                            <a href="blog-single.html">admin</a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="bi bi-clock" />{" "}
                                                            <a href="blog-single.html">
                                                                <time dateTime="2020-01-01">August, 17</time>
                                                            </a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-comment"></i>
                                                            <a href="blog-single.html">Commnets Off</a>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-folder"></i>
                                                            <a href="blog-single.html">Categories : &nbsp;
                                                                <a href="https://financialsbook.com/category/tax/" rel="category tag">tax</a>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul style={{marginTop: "10px"}}>
                                                        <li className="d-flex align-items-center">
                                                            <i className="fas fa-bookmark"></i>
                                                            <a href="blog-single.html">Tag : &nbsp;
                                                                <a href="https://financialsbook.com/category/tax/" rel="category tag">2020, Deadline, IncomeTax, Salaried, TAX</a>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="entry-content">
                                                    {/*<p>*/}
                                                    {/*    Similique neque nam consequuntur ad non maxime aliquam quas.*/}
                                                    {/*    Quibusdam animi praesentium. Aliquam et laboriosam eius aut*/}
                                                    {/*    nostrum quidem aliquid dicta. Et eveniet enim. Qui velit est*/}
                                                    {/*    ea dolorem doloremque deleniti aperiam unde soluta. Est cum*/}
                                                    {/*    et quod quos aut ut et sit sunt. Voluptate porro consequatur*/}
                                                    {/*    assumenda perferendis dolore.*/}
                                                    {/*</p>*/}
                                                    <div className="read-more">
                                                        <a href="/blog/IncomeTaxReturnDeadline">Read More</a>
                                                    </div>
                                                </div>
                                            </article>
                                            {/* End blog entry */}
                                        </div>
                                        {/* End blog entries list */}
                                    </div>
                                </div>
                            </section>
                            {/* End Blog Section */}
                    </main>
                    {/* Section-1 */}

                    <main className="cd__main">
                        {/* Start DEMO HTML (Use the following code into your project)*/}
                        <div className="container conbg" id="conbg">
                            <div className="bg2" id="bg2">
                                <div className="bg1" id="bg1">
                                    <div className="bg" id="bg">
                                        <div className="bg-text text-light ps-3">
                                            {/*<h3>*/}
                                            {/*   page 1*/}
                                            {/*    <br />*/}
                                            {/*    PutihNET Official*/}
                                            {/*</h3>*/}
                                            <p className="blog-Pg">
                                                Blogs FinancialsBook In Do you know what would be nice?
                                                <br />
                                                Launching a blog and every post going &nbsp;
                                                <strong>viral</strong>
                                                <br />
                                                Or maybe starting a YouTube channel and becoming an &nbsp;
                                                <strong>instant hit</strong>
                                                <br />
                                                As a blogger and digital marketer, whatever you start, you hope it’ll take off, right?
                                                <strong>instant hit</strong>
                                                <br />
                                                Well in this post, I’m going to share a story of my failed attempt as a blogger and how I plan to redeem myself by building a  &nbsp;
                                                <strong>blog financialsbook</strong>
                                                <br />
                                                What’s a blog empire, you ask? Keep reading…
                                                <br />
                                                I’ll explain my definition of a blog empire, who else has built them and how you can learn more about blogging and building a blog empire yourself.
                                                <br />
                                                Let’s get straight into it!
                                            </p>
                                            <a className="text-light" href="#bg1">
                                                <svg
                                                    viewBox="0 0 448 512"
                                                    width={30}
                                                    fill="#fff"
                                                    title="angle-double-right"
                                                >
                                                    <path className="blog-path" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="bg-text text-light ps-3">
                                        <h3>
                                            What Do I Mean by a Blog?
                                            <br />
                                        </h3>
                                        <p style={{color: "white"}}>First off, let me define what I mean by a blog.</p>
                                        <p className="blog-Pg">
                                            Many people think of a blog as a small online diary or someone sharing what they had for dinner… Or when an offline business announces that they’re moving their office to a different location.
                                            <br />
                                            Yes, these types of blogs might work, if they already have an audience or following.
                                            <br />
                                            The type of blog I’m talking about though is a business in itself.
                                            <br />
                                            A successful blog these days must consist of the following criteria:
                                            <br />
                                            <ul>
                                                <li>Focus on a niche market</li>
                                                <li>Provide high quality content</li>
                                                <li>Educate people or provide value in some way</li>
                                                <li>Monetize their traffic with either ads, affiliate marketing, a service or product</li>
                                            </ul>
                                            In a nutshell, when a blog focuses on providing value through content, builds a community of followers and helps people solve their problems, it builds a foundation of a solid business that will earn revenue for the business to continue helping people achieve their goals.
                                            <br />
                                            It’s a win, win situation.
                                        </p>
                                        <a className="text-light" href="#bg2">
                                            <svg
                                                viewBox="0 0 448 512"
                                                width={30}
                                                fill="#fff"
                                                title="angle-double-right"
                                            >
                                                <path className="blog-path" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="bg-text text-light ps-3">
                                    <h3>
                                        What Do I Mean by Blog FinancialsBook?
                                        <br />
                                    </h3>
                                    <p className="blog-Pg">
                                        It’s simple really…
                                        <br />
                                        If you were to think of a &nbsp;<strong>business empire</strong>&nbsp;,you would think of multiple businesses owned by the same corporation.
                                        <br />
                                        Bisnis Voucher
                                    </p>
                                    <a className="text-light" href="#conbg">
                                        <svg
                                            viewBox="0 0 448 512"
                                            width={30}
                                            fill="#fff"
                                            title="angle-double-right"
                                        >
                                            <path className="blog-path" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* END EDMO HTML (Happy Coding!)*/}
                    </main>


                </div>
            </>

        );
    }
}
export default Blog;
