import React from "react";

class WebApplicationDevelopment extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Website is the Most Powerful Marketing Tool</h3>
                                <button className="contact-change6">
                                    <a href="/ContactUS"> Lets Develop Your Website</a>
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/wb1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Quality Web & Application Development Services
                                    </h3>
                                    <p>
                                        <a href="https://www.instagram.com/financialsbook/" target="_blank" style={{fontSize: '14px', backgroundColor: 'rgb(246, 246, 246)'}}>Financialsbook.com</a>
                                        &nbsp;&nbsp;  is a web development agency that has top web developers in Pakistan. Tell us what you want to develop and we will bring it to fruition. From e-commerce to web development services, we do everything in between.  You will definitely love the website we will develop for you. Hire us for your next website development project and get your web developed within your time and budget.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/wb2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Responsive Website Design</h3>
                                    <p>
                                        <a href="https://www.instagram.com/financialsbook/" target="_blank" style={{fontSize: '14px', backgroundColor: 'rgb(246, 246, 246)'}}>Financialsbook.com</a>
                                        &nbsp;&nbsp;  is a web development agency that has top web developers in Pakistan. Tell us what you want to develop and we will bring it to fruition. From e-commerce to web development services, we do everything in between.  You will definitely love the website we will develop for you. Hire us for your next website development project and get your web developed within your time and budget.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/wb3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        CMS based Website Development
                                    </h3>
                                    <p>
                                        We use different Content Management System platforms ranging from custom developed CMS, WordPress development services, Drupal development services, Joomla development services, and OScommerce development services to create attractive content that lures your target audience.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/wb4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Register Domain Now
                                    </h3>
                                    <p>
                                        Being one of the leading E-commerce web design company in Pakistan, we know how to create E-commerce websites that actually sell your products. As an E-commerce web & application  development company, we help you grow your business online by building your own online store. Besides offering E-commerce development services we also provide support, we also provide support for local payment gateway integration, logistics management solution and self-service CMS to monitor your sales, add new products and more.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/wb5.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        SEO Optimized Websites
                                    </h3>
                                    <p>
                                        Ranking your website on Google is important to get more traffic and increase your sales. We make websites that are SEO optimized so you don’t have to worry about your rankings. You can also avail our SEO services to make sure that your website stays ranks #1 on google. Take advantage of our website SEO services to beat your competition and lead the way in the digital world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default WebApplicationDevelopment;
