import React from "react";

class DigitalMarketing extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Top Digital Marketing Agency In Pakistan</h3>
                            </div>
                            <button className="contact-change6">
                                <a href="/ContactUS"> Let Rant#1 In Google</a>
                            </button>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/DM1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>Search Engine Optimization Service (SEO)</h3>
                                    <p>Search Engine Optimization
                                        <a href="https://hosting.financialsbook.com/products/seo">SEO</a>
                                        &nbsp;is one of the digital marketing services. Our&nbsp;SEO&nbsp;services may help your business
                                        enhance by focusing higher search engine rankings, quality website traffic &amp; measureable results.
                                        We use the advanced techniques, strategies &amp; tools to help you improve your website and boost your
                                        rankings in the search engines for the right keywords and phrases related to your business. SEO is
                                        an ever-green solution &amp; you may also create custom content which contains right information related
                                        to the topic. Our highly qualified and professional&nbsp;SEO&nbsp;team understands each &amp; every point
                                        related to your Google rankings.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/DM2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Search Engine Marketing Service (SEM)
                                    </h3>
                                    <p>Digital marketing services include pay-per-click advertisement (PPC) which provides you to choose
                                        keywords &amp; phrases you intend to boost your advertisements. So, when people look for those
                                        keywords, your ads will show above organic results. It may enhance the exposure of your brand online
                                        visibility &amp; help you attract more customers and generate more revenue. One of the biggest
                                        advantage of PPC advertising is that the quickest results i.e. we don’t have to wait for results.
                                        PPC ads may start targeted traffic within a minute. If you are searching for super-fast traffic to your
                                        site, PPC is a best option. <a href="https://www.instagram.com/financialsbook/">Financialsbook.com</a>
                                        , Digital Marketing highly qualified PPC experts very carefully manage each campaign.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/DM3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Social Media Marketing(SMM)
                                    </h3>
                                    <p>
                                        <a href="https://onesuite.work/office/social-media-marketing">Social Media Marketing</a>
                                        &nbsp;(SMM) a digital marketing service may help you establish a presence on the top social
                                        media channels &amp; networks such as Facebook, Twitter, LinkedIn, Google Plus &amp; Instagram.
                                        digital marketing Services provide you to busy current and potential customers &amp; establish
                                        relationships which encourage them to purchase. Followers may also recommend your business to
                                        their loved ones who might need your digital marketing Services. You may also use social media to track public
                                        sentiments about your digital marketing agency &amp; implement output form fans and followers.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/DM4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        Content Marketing
                                    </h3>
                                    <p>
                                        <a href="https://onesuite.work/office/sms-marketing">Content Marketing</a>
                                        &nbsp;consists each &amp; everything from blog post to infographics, videos &amp; ebooks.
                                        Search engine as well website visitors impress fresh quality original content. If you intend
                                        your business to rank in search engines &amp; generate more traffic, you need to develop engaging
                                        content.<br/><a href="https://financialsbook.com/" target="_blank" rel="noopener">Financialsbook.com,
                                        </a>Digital Marketing provides with&nbsp;content writing services&nbsp;that helps your business present
                                            higher in search engine results for keywords &amp;
                                        phrases relevant to your industry, finally providing you to attract and convert more traffic.</p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/DM5.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>
                                        Email Marketing
                                    </h3>
                                    <p>
                                        <a href="https://onesuite.work/office/email-marketing">Email marketing</a>
                                        &nbsp;is a way to communicate your business directly into some ones daily &amp; allows you to reach
                                        current &amp; potential customers perfect in their inboxes and offer them with the information they
                                        require to purchase from your business. The right mix of communication to enhance your customer base
                                        and build ever-green customer relationships which directly affect in business development.
                                        Email marketing provide you to keep in touch and give important updates and information.&nbsp ;
                                        <a href="https://hosting.financialsbook.com/products/email-marketing" target="_blank" rel="noopener">
                                            Email marketing
                                        </a>
                                        &nbsp;is not only cost-effective but also
                                        provides you to right people with special information which guides them through the purchasing process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>

            </>

        );
    }
}
export default DigitalMarketing;