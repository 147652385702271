import React from "react";

class ItConsulting extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <section className=" section-5">
                        <div className="container">
                            <div className="section-title">
                                <h3>Our strategic IT consulting will help you automate and digitalise operations</h3>
                                <button className="contact-change6">
                                    <a href="/ContactUS"> Lets Consulting With Us</a>
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* ======= Features Section ======= */}
                    <section className="features">
                        <div className="container">
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/it1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>ENTERPRISE ARCHITECTURE ADVISORY</h3>
                                    <p>
                                        Our Enterprise Architecture experts can help you make the transition from outdated
                                        and ineffective IT delivery systems and architectures to a cloud-based infrastructure that
                                        streamlines delivery and allows for implementation of new technologies into your software strategy.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5">
                                    <img src="/img/it2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7">
                                    <h3>
                                        IT STRATEGY CONSULTING &amp; NEW TECH
                                    </h3>
                                    <p>
                                        Today, you need to ensure the consistent delivery of high-quality IT products
                                        and services for your enterprise workflow and your customers. You also need to
                                        implement new technologies to stay competitive and meet the demands of modern
                                        users. Our IT solutions consulting will help your strike the balance between these
                                        two objectives, ensuring they don’t conflict between one another.
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                            <div className="row gy-4 align-items-center features-item" data-aos="fade-up">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/img/it3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1">
                                    <h3>SOFTWARE PORTFOLIO CONSULTING</h3>
                                    <p>
                                        Our IT advisors will conduct a deep analysis of how your business and employees
                                        use your existing&nbsp;
                                        <a href="https://financialsbook.com/enterprise-software-development/" target="_blank">enterprise software</a>
                                        , as well as of your enterprise mobile strategy. Then, they will provide suggestions for the required changes,
                                        new developments, and integration of third-party solutions to remove the existing roadblocks.&nbsp;For more than
                                        10 years now&nbsp;&nbsp;
                                        <a href="https://www.instagram.com/financialsbook/" target="_blank">financialsbook.com</a>
                                        &nbsp;has been helping organizations of different scale and structure to improve and modernise their IT strategies.
                                        Some of our IT experts have worked in the industry for over fifteen years. This has allowed us to craft an
                                        effective approach to IT consulting that enables us to deliver maximum benefit in the shortest.&nbsp;At The One
                                        Technologies, our Software Consulting Services offer everything from planning to building a first product
                                        implementation through complex, multi-product IT transformation. We offer you technological advice that is
                                        pragmatic as well as futuristic that gives your business a strong advantage.&nbsp;
                                        <span style={{fontSize: '14px'}}>
                                            Our strategic software consulting services will help you automate and digitalize operations, optimize the
                                            software portfolio, and implement the latest technologies with the help of our industry experts from different
                                            knowledge domains.
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {/* Features Item */}
                        </div>
                    </section>
                    {/* End Features Section */}
                </div>
            </>

        );
    }
}
export default ItConsulting;
