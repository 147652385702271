import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
class IncomeTaxReturnDeadline extends React.Component {
    render() {
        return(
            <>
                <div className="page-content">
                    <div className="sect2-textpart">
                        <div className="section-title">
                            <h2 className="price-head sec2-h2">Income Tax Return Deadline - 30 September, 2020</h2>
                        </div>

                        {/* ======= Blog Section ======= */}
                        <section id="blog" className="blog">
                            <div className="container table-blog" data-aos="fade-up">
                                <div className="row">
                                    <div className="col-lg-8 entries">
                                        <div className="col-md-6 img-sect">
                                            <img className="income-dd" src="/img/ITRD-img.jpg" />
                                        </div>
                                    </div>
                                    {/* End blog entries list */}
                                    <div className="col-lg-4">
                                        <div className="sidebar">
                                            <h3 className="sidebar-title">Search</h3>
                                            <div className="sidebar-item search-form">
                                                <form action="">
                                                    <input type="text" placeholder="search..." />
                                                    <button type="submit">
                                                        <div>
                                                            <AiOutlineSearch
                                                                style={{
                                                                    color: '#ffffff',
                                                                    fontSize: "25px"
                                                                }}
                                                            />
                                                        </div>
                                                    </button>
                                                </form>
                                            </div>
                                            {/* End sidebar search form*/}
                                            <h3 Style={{textAlign: 'left'}} className="sidebar-title">Recent Posts</h3>
                                            <div className="sidebar-item categories">
                                                <ul Style={{textAlign: 'left'}}>
                                                    <li>
                                                        <a href="https://financialsbook.com/know-your-tax-rate/" aria-current="page">Know Your Tax Rate</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://financialsbook.com/income-tax-return-deadline-30th-september-2020/">Income Tax Return Deadline – 30th September, 2020</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* End sidebar Recent Comment form*/}
                                            <h3 Style={{textAlign: 'left'}} className="sidebar-title">Recent Comments</h3>
                                            <div className="sidebar-item categories">
                                                <ul Style={{textAlign: 'left'}}>
                                                    <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://bactrimrbv.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin for dogs side effects
                                                                </a>
                                                                on
                                                            </span>
                                                        <a href="https://financialsbook.com/#comment-28">Home</a>
                                                    </li>
                                                    <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://bactrimqwx.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin 500
                                                                </a>
                                                                on
                                                            </span>

                                                        <a href="https://financialsbook.com/#comment-27">Home</a></li>
                                                    <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://cephalexinfds.com" className="url" rel="ugc external nofollow">
                                                                    cephalexin used for
                                                                </a>
                                                                o
                                                            </span>

                                                        <a href="https://financialsbook.com/#comment-26">Home</a></li>
                                                    <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://ciprofloxacinbtg.com" className="url" rel="ugc external nofollow">
                                                                    ciprofloxacin and dexamethasone
                                                                </a>
                                                                on
                                                            </span>

                                                        <a href="https://financialsbook.com/#comment-25">Home</a></li>
                                                    <li className="recentcomments">
                                                            <span className="comment-author-link">
                                                                <a href="http://zithromaxeng.com" className="url" rel="ugc external nofollow">
                                                                    buy zithromax
                                                                </a>
                                                                on
                                                            </span>

                                                        <a href="https://financialsbook.com/#comment-24">Home</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* End sidebar Archives form*/}
                                            <h3 Style={{textAlign: 'left'}} className="sidebar-title">Archives</h3>
                                            <div className="sidebar-item categories">
                                                <ul Style={{textAlign: 'left'}}>
                                                    <li><a href="https://financialsbook.com/2020/09/">September 2020</a></li>
                                                    <li><a href="https://financialsbook.com/2020/08/">August 2020</a></li>
                                                </ul>
                                            </div>
                                            {/* End sidebar Categories form*/}
                                            <h3 Style={{textAlign: 'left'}} className="sidebar-title">Categories</h3>
                                            <div className="sidebar-item categories">
                                                <ul Style={{textAlign: 'left'}}>
                                                    <li className="cat-item cat-item-11"><a
                                                        href="https://financialsbook.com/category/tax/">tax</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* End sidebar Meta form*/}
                                            <h3 Style={{textAlign: 'left'}} className="sidebar-title">Meta</h3>
                                            <div className="sidebar-item categories">
                                                <ul Style={{textAlign: 'left'}}>
                                                    <li><a rel="nofollow" href="https://financialsbook.com/wp-login.php">Log in</a></li>
                                                    <li><a href="https://financialsbook.com/feed/">Entries feed</a></li>
                                                    <li><a href="https://financialsbook.com/comments/feed/">Comments feed</a></li>

                                                    <li><a href="https://wordpress.org/">WordPress.org</a></li>
                                                </ul>
                                            </div>
                                            {/* End sidebar tags*/}
                                        </div>
                                        {/* End sidebar */}
                                    </div>
                                    {/* End blog sidebar */}
                                </div>
                            </div>
                        </section>
                        {/* End Blog Section */}


                        {/* End #main */}






                    </div>
                </div>
            </>
        )
    }
}
export default IncomeTaxReturnDeadline;
